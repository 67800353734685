import {
  Box,
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ResidualGroupAdjustments,
  ResidualGroupAdjustmentsLineItems,
} from '@ozark/functions/src/functions/callable/dispatch_GetResidualGroupAdjustments';
import {groupBy} from '@s-libs/micro-dash';
import {currentFormatter} from '../../ReportingPage';

interface Props {
  groupPayouts: ResidualGroupAdjustments[] | null;
  groupPayoutAdj: number;
}
export const GroupAdjustments = ({groupPayouts, groupPayoutAdj}: Props) => {
  return (
    <>
      {!!groupPayouts && (
        <Box mt={2} p={2}>
          <Typography variant="h5">Group Adjustments</Typography>

          <TableContainer component={Paper}>
            <MuiTable sx={{maxWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(groupBy(groupPayouts, groupPayout => groupPayout.group.name)).map(
                  ([groupName, payouts], index) =>
                    payouts
                      .map(x => x.lineItems)
                      .flatMap(x => x)
                      .flatMap(x => x as ResidualGroupAdjustmentsLineItems)
                      .map(({amount, description}, idx) => (
                        <TableRow
                          key={`${groupName}-${index}-${idx}`}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell align="right">{description}</TableCell>

                          <TableCell align="right" sx={{color: amount >= 0 ? 'green' : 'red'}}>
                            {currentFormatter.format(amount)}
                          </TableCell>
                        </TableRow>
                      ))
                )}
                <TableRow key="summary">
                  <TableCell align="right" sx={{fontWeight: 500}}>
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{color: groupPayoutAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                  >
                    {currentFormatter.format(groupPayoutAdj)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
