import TodayIcon from '@mui/icons-material/Today';
import {Box, Divider, InputBase, MenuItem, Select, SelectChangeEvent, Stack} from '@mui/material';
import {VolumeFilterTypes} from '@ozark/functions/lib/functions/express/private/types/Reports';
import {AgentSalesRecord, AgentsSales} from '@ozark/functions/src/functions/express/private/types';
import {CancelTokenSource} from 'axios';
import {add} from 'date-fns';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  AutoCompleteInputBase,
  BoxCentered,
  BoxParentHeight,
  ButtonExportCsv,
  Filter,
  FilterOption,
  forceActiveFilter,
  InfoLoading,
  InfoNoData,
  MonthYearPicker,
  Square,
  Table,
} from '../..';
import {
  formatAsPercent,
  formatterCurrency,
  formatterNumber,
  SearchCriteria,
  useApiContainer,
  useUserInfo,
} from '../../..';
import {Column} from '../../../api/Column';
import {CancelOperationMessage} from '../../../api/Constants';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';

const ensureNumbers = (agentSales: AgentsSales | null) => {
  return agentSales
    ? {
        ...agentSales,
        agentSales: agentSales.agentSales.map(i => ({
          ...i,
          salesVolume: Number(i.salesVolume ?? 0),
          salesCount: Number(i.salesCount ?? 0),
        })),
      }
    : undefined;
};

export const AgentStatisticsSales = () => {
  const api = useApiContainer();
  const history = useHistory();
  const {path: pathname} = useRouteMatch();
  const {isPortal} = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [agentsSales, setAgentsSales] = useState<AgentsSales>();
  const [agentSelected, setAgentSelected] = useState<AgentSalesRecord>();
  const [agentsFiltered, setAgentsFiltered] = useState<AgentSalesRecord[]>([]);
  const {year, month} = useQueryMonthYear();
  const [searchAgentId, setSearchAgentId] = useState<string>();
  const [volumeFilterType, setVolumeFilterType] = useState<VolumeFilterTypes>(
    VolumeFilterTypes.All
  ); // All or a Custom month
  const [filters, setFilters] = useState<Filter>({}); // defines date scope
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
  const [pageConfig, setPageConfig] = useState<SearchCriteria>({
    limit: 1000, // page size
    offset: 1, // page number
    orderBy: 'agentName',
    order: 'asc',
  });
  const hasData = Boolean(agentsFiltered?.length);
  const handleAgentSelect = (agent: AgentSalesRecord | null) => {
    setSearchAgentId(agent?.agentId);
  };

  useEffect(() => {
    setLoading(true);
    let isMounted: true | undefined = true;
    const cancelSource = api?.agentStatistics.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    api?.agentStatistics
      .getAgentsStatisticsSales(pageConfig, Object.values(filters), cancelSource?.token)
      .then(result => isMounted && setAgentsSales(ensureNumbers(result)))
      .finally(() => isMounted && setLoading(false));
    return () => (isMounted = undefined);
  }, [filters]);
  useEffect(
    () => setAgentsFiltered(filterAgents(agentsSales?.agentSales, searchAgentId)),
    [agentsSales, searchAgentId]
  );
  useEffect(() => {
    const agentsFilteredSorted = orderBy(
      agentsFiltered,
      [pageConfig.orderBy],
      [pageConfig.order.toLowerCase() as 'desc' | 'asc']
    );
    setAgentsFiltered(agentsFilteredSorted);
  }, [pageConfig]);
  useEffect(() => {
    if (year === 0 || month === 0) return;
    const startingDate = new Date(Date.UTC(year, month - 1, 1));
    const transactionDate = forceActiveFilter(AgentSalesFilters, 'transactionDate', '__between', [
      startingDate,
      add(startingDate, {months: 1}),
    ]);
    setFilters(previous => ({...previous, transactionDate}));
    setVolumeFilterType(VolumeFilterTypes.Custom);
  }, [year, month]);

  const handleVolumeFilterChange = (event: SelectChangeEvent) => {
    const type = event.target.value as VolumeFilterTypes;

    if (type === VolumeFilterTypes.All) {
      setFilters({});
      history.push({pathname, search: ``}); // clear month and year params from query string
    }

    setVolumeFilterType(type);
  };

  return (
    <>
      <Box display="flex">
        <Box flex={1} />
        <AutoCompleteInputBase
          selected={agentSelected}
          setSelected={setAgentSelected}
          icon
          placeholder="Select agent..."
          width={200}
          options={(agentsSales?.agentSales ?? []) as AgentSalesRecord[]}
          getOptionLabel={(agent: AgentSalesRecord) => agent.agentName}
          getOptionKey={(agent: AgentSalesRecord) => agent.agentId}
          onItemSelect={handleAgentSelect}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <BoxCentered minWidth={250}>
          <TodayIcon style={{color: '#0000008a'}} />
          &emsp;
          <Select
            sx={{
              width: 250,
            }}
            size="small"
            value={volumeFilterType}
            label="Date"
            onChange={handleVolumeFilterChange}
            input={<InputBase />}
          >
            <MenuItem value={VolumeFilterTypes.All}>ALL</MenuItem>
            <MenuItem value={VolumeFilterTypes.Custom}>CUSTOM</MenuItem>
          </Select>
        </BoxCentered>
        {volumeFilterType === VolumeFilterTypes.Custom && (
          <>
            <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
            <MonthYearPicker />
          </>
        )}
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <ButtonExportCsv
          filename="agent-statistics-sales"
          rows={agentsFiltered}
          columnsConfig={getColumns(isPortal)}
        />
      </Box>
      {loading && <InfoLoading />}
      {!loading && !hasData && <InfoNoData />}
      {!loading && hasData && (
        <>
          <Stack mt={2} direction="row" spacing={2}>
            <Box flex={1}>
              <Square
                lines={{
                  'All Agents Sales Count': formatterNumber.format(
                    Number(agentsSales?.totalAgentsSalesCount ?? 0)
                  ),
                }}
                center
              />
            </Box>
            <Box flex={1}>
              <Square
                lines={{
                  'All Agents Sales Volume': formatterNumber.format(
                    Number(agentsSales?.totalAgentsVolume ?? 0)
                  ),
                }}
                center
              />
            </Box>
          </Stack>
          <BoxParentHeight my={2}>
            <Table
              columns={getColumns(isPortal)}
              data={{
                sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
                limit: pageConfig.limit,
                offset: pageConfig.offset,
                totalCount: agentsFiltered.length,
                data: agentsFiltered,
              }}
              onRetrieveData={setPageConfig}
              stickyHeader
            />
          </BoxParentHeight>
        </>
      )}
    </>
  );
};

const filterAgents = (agents: AgentSalesRecord[] = [], agentId?: string) =>
  agentId ? agents.filter(a => a.agentId === agentId) : agents;

const getColumns = (isPortal: boolean): Column<AgentSalesRecord>[] => [
  {
    id: 'agentName',
    label: 'Agent Name',
    export: true,
    sortable: true,
  },
  {
    id: 'mids',
    label: 'Boarded MIDs',
    export: false,
    sortable: true,
    selector: row => {
      if (!row.mids.length) {
        return '-';
      }

      const midsQueryParams = row.mids.map(mid => `mids=${mid}`).join('&');
      const path = isPortal ? '/applications/boarded' : '/boarded';

      return <a href={`${path}?${midsQueryParams}`}>{row.mids.length}</a>;
    },
  },
  {
    id: 'salesCount',
    label: 'Sales Count',
    sortable: true,
    numeric: true,
    selector: row => formatterNumber.format(Number(row.salesCount ?? 0)),
    export: true,
  },
  {
    id: 'salesCountRatio',
    label: 'Sales Count / Total Count',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.salesCountRatio),
    export: true,
  },
  {
    id: 'salesVolume',
    label: 'Sales Volume',
    sortable: true,
    numeric: true,
    selector: row => formatterCurrency.format(Number(row.salesVolume ?? 0)),
    export: true,
  },
  {
    id: 'salesVolumeRatio',
    label: 'Sales Volume / Total Volume',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.salesVolumeRatio),
    export: true,
  },
];

export const AgentSalesFilters: FilterOption[] = [
  {
    id: 'transactionDate',
    column: 'transactionDate',
    label: 'Date Range',
    type: 'dateRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
