import {useCallback} from 'react';
import {TicketAssignee, TicketAssociation, TicketHistoryRecordType} from '../../..';
import {useTicketHistoryRecord} from './useTicketHistoryRecord';
import {useTicketId} from './useTicketId';
import {useTicketStatus} from './useTicketStatus';

export function useHistoryRecord() {
  const {ticketId} = useTicketId();
  const {isDraft} = useTicketStatus();
  const {addTicketHistoryRecord} = useTicketHistoryRecord();

  const addHistoryRecord = useCallback(
    async (
      title: string,
      recordType: TicketHistoryRecordType,
      forceAdd?: boolean,
      titlePortal?: string,
      hidden?: boolean,
      assignee?: TicketAssignee,
      association?: TicketAssociation
    ) => {
      await addTicketHistoryRecord(
        ticketId,
        isDraft,
        title,
        recordType,
        forceAdd,
        titlePortal,
        hidden ?? false,
        assignee,
        association
      );
    },
    [addTicketHistoryRecord, ticketId, isDraft]
  );

  return {
    addHistoryRecord,
  };
}
