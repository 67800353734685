import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, Button, Stack} from '@mui/material';
import {AsyncState, GroupView} from '@ozark/common';
import {useCallable} from '@ozark/common/hooks/useCallable';
import Persona, {Client} from 'persona';
import {Field, InquiryAttributes} from 'persona/dist/lib/interfaces';
import {useCallback, useEffect} from 'react';

type Props = {
  applicationId: string;
  group: AsyncState<GroupView>;
  token: string;
  onSuccess: (license: string) => void;
};

let personaClient: Client;
export const PersonaIdVerificationLink = ({onSuccess, group, token, applicationId}: Props) => {
  const {
    encryptField,
    getPersonaIdConfig,
    documentsUploadPersonaSaveData,
    getDocumentsUploadPersonaGetData,
  } = useCallable();

  const initPersona = useCallback(
    async (refId: string) => {
      if (personaClient) return;

      getPersonaIdConfig().then(config => {
        personaClient = new Persona.Client({
          templateId: config.data.template,
          environmentId: config.data.environment,
          referenceId: refId,
          onReady: () => {},
          onComplete: async ({
            inquiryId,
            status,
            fields,
          }: {
            inquiryId: string;
            status: string;
            fields: Record<string, Field> | InquiryAttributes;
          }) => {
            const selectedFields = fields as any;
            if (status === 'completed' && selectedFields['identification-class'].value === 'dl') {
              const newValue = {
                isVerified: true,
                driverLicenseNumber: selectedFields['identification-number'].value,
              } as any;
              await documentsUploadPersonaSaveData({token: token, data: newValue});
              onSuccess(selectedFields['identification-number'].value);
            }

            personaClient.cancel(false);
          },
        });
      });
    },
    [getPersonaIdConfig, documentsUploadPersonaSaveData, onSuccess, token]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getDocumentsUploadPersonaGetData({
          token,
        });
        if (response.status === 'ok') {
          if (!response.data?.referenceId) {
            const encryptEmail = async () => await encryptField(response.email);

            encryptEmail().then(async encryptedResult => {
              if (encryptedResult) {
                const personaId = {
                  isVerified: false,
                  referenceId: encryptedResult.encryptedText,
                  rerefenceIdSalt: encryptedResult.salt,
                };
                await documentsUploadPersonaSaveData({token: token, data: personaId});
                initPersona(encryptedResult.encryptedText);
              }
            });
          } else {
            initPersona(response.data.referenceId);
          }
        } else {
          console.error(response);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [
    getDocumentsUploadPersonaGetData,
    documentsUploadPersonaSaveData,
    encryptField,
    token,
    initPersona,
    applicationId,
  ]);

  return (
    <Box sx={{margin: theme => theme.spacing(0, 2, 0, 2)}}>
      <Button
        variant="contained"
        size={'small'}
        color={'secondary'}
        sx={{fontSize: '1em'}}
        onClick={() => {
          personaClient.open();
        }}
        fullWidth={true}
        endIcon={<ArrowForwardIosIcon />}
      >
        <Stack sx={{width: '100%', fontSize: '1em', paddingLeft: '24px'}}>
          <span>Verify your account</span>
          <span>with Persona ID</span>
        </Stack>
      </Button>
    </Box>
  );
};
