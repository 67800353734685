import {
  AgentSubCollection,
  ApplicationDocument,
  ApplicationSubCollection,
  LeadSubCollection,
} from '@ozark/common';
import {createContext, useContext} from 'react';

interface NoteContextProps {
  allowAddComments?: boolean;
  allowAttachments?: boolean;
  allowDeleteMessages?: boolean;
  allowEditMessages?: boolean;
  noteIdToReply?: string | null;
  notesSubCollection?:
    | AgentSubCollection.notes
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes;
  onEditClick: (id: string | null) => void;
  onReplyClick: (noteId: string | null) => void;
  documentTypeOptions: ApplicationDocument[];
}

const DEFAULT_CONTEXT_VALUE: NoteContextProps = {
  onEditClick: () => {},
  onReplyClick: () => {},
  documentTypeOptions: [],
};

const NoteContext = createContext<NoteContextProps>(DEFAULT_CONTEXT_VALUE);

const useNoteContext = () => useContext(NoteContext);

export {NoteContext, useNoteContext};
export type {NoteContextProps};
