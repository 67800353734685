import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, Chip, CircularProgress, Grid, IconButton, Typography} from '@mui/material';
import {
  ActiveFilter,
  DATE_FORMAT_RESERVES,
  DATETIME_FORMAT,
  FilterOption,
  Filters,
  FilterUpdate,
  Loading,
  ReserveFilters,
  Square,
  Table,
} from '@ozark/common/components';
import {ReserveActivity} from '@ozark/functions/src/functions/express/private/types/Reserve';
import {sumBy} from '@s-libs/micro-dash';
import {CancelTokenSource} from 'axios';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {
  AllMIDs,
  useApiContainer,
  useMidsContainer,
  useNotification,
  UserRoles,
  useUserInfo,
} from '../../..';
import {Column} from '../../../api/Column';
import {CancelOperationMessage} from '../../../api/Constants';
import {useExportToCsv} from '../../common';
import {Bar, Filter} from '../common';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {
  currentFormatter,
  forceActiveFilter,
  percentFormatter,
  useReportingPageStyles,
} from '../Reporting';
import {EditReserveDialog} from './EditReserveDialog';

const recordTypeMap = {
  B: 'Calculated Reserve',
  C: 'Calculated Release',
  '3': 'One Time Reserve',
  '4': 'One Time Release',
  '5': 'Record Adjustment Reserve',
  '6': 'Record Adjustment Release',
  '7': 'Transfer Funds on Reserve',
};

type ReservesProps = {
  filterOptions?: FilterOption[];
};

const getFormattedOrLoading = (numberStr: number | undefined) => {
  return numberStr === undefined ? <CircularProgress /> : currentFormatter.format(numberStr);
};

export const Reserves = ({filterOptions = ReserveFilters}: ReservesProps) => {
  const showNotification = useNotification();
  const {isErpAdmin, role} = useUserInfo();
  const showIsDisplayed = role === UserRoles.agentSupport;
  const classes = useReportingPageStyles();
  const api = useApiContainer();
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const [filters, setFilters] = useState<Filter>({});
  const [reserves, setReserves] = useState<ReserveActivity[] | null | undefined>();
  const [balances, setBalances] = useState<{
    displayedBalance: number;
    balance: number;
    displayReserves: number;
    reserves: number;
    displayReleases: number;
    releases: number;
  } | null>();
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  useEffect(() => {
    if (!reserves) {
      setBalances(null);
      return;
    }
    const totalReserves = sumBy(reserves || [], e => parseFloat(e.reserveAmount || '0'));
    const totalReleases = sumBy(reserves || [], e => parseFloat(e.releaseAmount || '0'));
    const reservesDisplayed = reserves.filter(x => x.isDisplayed);
    const totalReservesDisplayed = sumBy(reservesDisplayed || [], e =>
      parseFloat(e.reserveAmountUpdated || e.reserveAmount || '0')
    );
    const totalReleasesDisplayed = sumBy(reservesDisplayed || [], e =>
      parseFloat(e.releaseAmountUpdated || e.releaseAmount || '0')
    );
    setBalances({
      displayedBalance: totalReservesDisplayed - totalReleasesDisplayed,
      balance: totalReserves - totalReleases,
      displayReserves: totalReservesDisplayed,
      reserves: totalReserves,
      displayReleases: totalReleasesDisplayed,
      releases: totalReleases,
    });
  }, [reserves]);

  useEffect(() => {
    if (mids.promised || !mids.data) return;

    forceSelectMidIfNeeded();
  }, [mids, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    const cancelRequestToken = cancelRequests();
    setReserves(null);
    getReserves(cancelRequestToken);
  }, [filters]);

  const cancelRequests = () => {
    const cancelSource = api?.reserves.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    return cancelSource;
  };

  const getReserves = (cancelSource: CancelTokenSource | undefined) => {
    api?.reserves
      .getReserveActivity(selectedMid, Object.values(filters), cancelSource?.token)
      .then(reserves => {
        let sortedByDateDescAndEmptyToEnd = reserves?.sort((x, y) =>
          !x.activityDate || new Date(x.activityDate) < new Date(y.activityDate) ? 1 : -1
        );

        setReserves(sortedByDateDescAndEmptyToEnd);
      });
  };

  const forceFilterByMid = (mid: string) => {
    const midFilter = forceActiveFilter(filterOptions, 'mid', '__eq', mid);
    setFilters(previous => ({...previous, mid: midFilter}));
  };

  const setFiltersInCorrectOrder = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      forceFilterByMid(mid);
    }
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersInCorrectOrder(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFiltersInCorrectOrder({...filters, [filter.option.column]: filter});
  };

  const exportToCsv = useExportToCsv({
    filename: 'reserves-report',
    rows: reserves ?? [],
    columnsConfig: showIsDisplayed ? columnsConfigIsDisplayed : columnsConfig,
  });

  const handleOnSubmitUpdated = useCallback(async (row: ReserveActivity) => {
    try {
      await api?.reserves.saveUpdatedValues(row);
      setReserves(prevState => {
        if (!prevState) {
          return prevState;
        }
        const rowToUpdate = prevState.find(x => x.id === row.id);
        if (rowToUpdate) {
          rowToUpdate.isDisplayed = row.isDisplayed;
          rowToUpdate.grossSalesAmountUpdated = row.grossSalesAmountUpdated;
          rowToUpdate.reserveReleasePercentUpdated = row.reserveReleasePercentUpdated;
          rowToUpdate.reserveAmountUpdated = row.reserveAmountUpdated;
          rowToUpdate.releaseAmountUpdated = row.releaseAmountUpdated;
        }
        return [...prevState];
      });
      return true;
    } catch (err: any) {
      console.error(`failed to save Updated Values. ${err.toString()}`);
      showNotification('error', 'Failed to save.');
      return false;
    }
  }, []);

  const tileXs = 4;

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Reserve"
        midsLoaded
        mids={midsOptions}
        selectedMid={selectedMid}
        onMidSelect={onMidSelect}
        Filters={<Filters options={ReserveFilters} onApplyFilter={handleApplyFilter} />}
        exportToCsv={exportToCsv}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {!isEmpty(mids.data) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATETIME_FORMAT)}</b>' and '
                          <b>{format(filter.value?.[1] as Date, DATETIME_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'date' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATE_FORMAT_RESERVES)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    className={classes.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          <Grid item xs={tileXs}>
            <Square
              lines={{
                Reserves: getFormattedOrLoading(
                  showIsDisplayed ? balances?.reserves : balances?.displayReserves
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={tileXs}>
            <Square
              lines={{
                Releases: getFormattedOrLoading(
                  showIsDisplayed ? balances?.releases : balances?.displayReleases
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={tileXs}>
            <Square
              lines={{
                'Current Reserve Balance': getFormattedOrLoading(
                  showIsDisplayed ? balances?.balance : balances?.displayedBalance
                ),
              }}
              center
            />
          </Grid>
          {showIsDisplayed && (
            <>
              <Grid item xs={tileXs}>
                <Square
                  lines={{'Displayed Reserves': getFormattedOrLoading(balances?.displayReserves)}}
                  center
                />
              </Grid>
              <Grid item xs={tileXs}>
                <Square
                  lines={{'Displayed Releases': getFormattedOrLoading(balances?.displayReleases)}}
                  center
                />
              </Grid>
              <Grid item xs={tileXs}>
                <Square
                  lines={{
                    'Displayed Reserve Balance': getFormattedOrLoading(balances?.displayedBalance),
                  }}
                  center
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {reserves && (
              <Table
                stickyHeader
                scrollableBody
                customHeight="75vh"
                rows={reserves}
                columns={showIsDisplayed ? columnsConfigIsDisplayed : columnsConfig}
                actions={
                  showIsDisplayed
                    ? (row: ReserveActivity) => (
                        <EditReserveDialog reserveActivity={row} onSubmit={handleOnSubmitUpdated} />
                      )
                    : undefined
                }
              />
            )}
            {!reserves && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const getActivityDate = (row: ReserveActivity) => {
  if (!row.activityDate) return 'No Date';
  const date = new Date(row.activityDate);
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, DATE_FORMAT_RESERVES);
};

const columnsConfigIsDisplayed = [
  {
    id: 'activityDate',
    label: 'Activity Date',
    selector: getActivityDate,
    export: getActivityDate,
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'grossSalesAmount',
    label: 'Gross Amount',
    numeric: true,
    selector: row => {
      if (!row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.grossSalesAmount || '0'));
    },
    export: row => {
      if (!row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.grossSalesAmount || '0'));
    },
  },
  {
    id: 'grossSalesAmountUpdated',
    label: 'Displayed Gross Amount',
    numeric: true,
    selector: row => {
      if (!row.grossSalesAmountUpdated && !row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.grossSalesAmountUpdated || row.grossSalesAmount || '0')
      );
    },
    export: row => {
      if (!row.grossSalesAmountUpdated && !row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.grossSalesAmountUpdated || row.grossSalesAmount || '0')
      );
    },
  },
  {
    id: 'reserveReleasePercent',
    label: 'Reserve Percent',
    numeric: true,
    selector: row => {
      if (!row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(parseFloat(row.reserveReleasePercent || '0') / 100);
    },
    export: row => {
      if (!row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(parseFloat(row.reserveReleasePercent || '0') / 100);
    },
  },
  {
    id: 'reserveReleasePercentUpdated',
    label: 'Displayed Reserve Percent',
    numeric: true,
    selector: row => {
      if (!row.reserveReleasePercentUpdated && !row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(
        parseFloat(row.reserveReleasePercentUpdated || row.reserveReleasePercent || '0') / 100
      );
    },
    export: row => {
      if (!row.reserveReleasePercentUpdated && !row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(
        parseFloat(row.reserveReleasePercentUpdated || row.reserveReleasePercent || '0') / 100
      );
    },
  },
  {
    id: 'releaseAmount',
    label: 'Release Amount',
    numeric: true,
    selector: row => {
      if (!row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.releaseAmount || '0'));
    },
    export: row => {
      if (!row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.releaseAmount || '0'));
    },
  },
  {
    id: 'releaseAmountUpdated',
    label: 'Displayed Release Amount',
    numeric: true,
    selector: row => {
      if (!row.releaseAmountUpdated && !row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.releaseAmountUpdated || row.releaseAmount || '0')
      );
    },
    export: row => {
      if (!row.releaseAmountUpdated && !row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.releaseAmountUpdated || row.releaseAmount || '0')
      );
    },
  },
  {
    id: 'reserveAmount',
    label: 'Reserve Amount',
    numeric: true,
    selector: row => {
      if (!row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.reserveAmount || '0'));
    },
    export: row => {
      if (!row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.reserveAmount || '0'));
    },
  },
  {
    id: 'reserveAmountUpdated',
    label: 'Displayed Reserve Amount',
    numeric: true,
    selector: row => {
      if (!row.reserveAmountUpdated && !row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.reserveAmountUpdated || row.reserveAmount || '0')
      );
    },
    export: row => {
      if (!row.reserveAmountUpdated && !row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.reserveAmountUpdated || row.reserveAmount || '0')
      );
    },
  },
  {
    id: 'recordType',
    label: 'Record Type',
    selector: row => recordTypeMap[row.recordType],
    export: row => recordTypeMap[row.recordType],
  },
  {
    id: 'isDisplayed',
    label: 'Displayed',
    selector: row => {
      return row.isDisplayed ? 'Yes' : 'No';
    },
    export: true,
  },
] as Column<ReserveActivity>[];

const columnsConfig = [
  {
    id: 'activityDate',
    label: 'Activity Date',
    selector: getActivityDate,
    export: getActivityDate,
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'grossSalesAmount',
    label: 'Gross Amount',
    numeric: true,
    selector: row => {
      if (!row.grossSalesAmountUpdated && !row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.grossSalesAmountUpdated || row.grossSalesAmount || '0')
      );
    },
    export: row => {
      if (!row.grossSalesAmountUpdated && !row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.grossSalesAmountUpdated || row.grossSalesAmount || '0')
      );
    },
  },
  {
    id: 'reserveReleasePercent',
    label: 'Reserve Percent',
    numeric: true,
    selector: row => {
      if (!row.reserveReleasePercentUpdated && !row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(
        parseFloat(row.reserveReleasePercentUpdated || row.reserveReleasePercent || '0') / 100
      );
    },
    export: row => {
      if (!row.reserveReleasePercentUpdated && !row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(
        parseFloat(row.reserveReleasePercentUpdated || row.reserveReleasePercent || '0') / 100
      );
    },
  },
  {
    id: 'releaseAmount',
    label: 'Release Amount',
    numeric: true,
    selector: row => {
      if (!row.releaseAmountUpdated && !row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.releaseAmountUpdated || row.releaseAmount || '0')
      );
    },
    export: row => {
      if (!row.releaseAmountUpdated && !row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.releaseAmountUpdated || row.releaseAmount || '0')
      );
    },
  },
  {
    id: 'reserveAmount',
    label: 'Reserve Amount',
    numeric: true,
    selector: row => {
      if (!row.reserveAmountUpdated && !row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.reserveAmountUpdated || row.reserveAmount || '0')
      );
    },
    export: row => {
      if (!row.reserveAmountUpdated && !row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(
        parseFloat(row.reserveAmountUpdated || row.reserveAmount || '0')
      );
    },
  },
  {
    id: 'recordType',
    label: 'Record Type',
    selector: row => recordTypeMap[row.recordType],
    export: row => recordTypeMap[row.recordType],
  },
] as Column<ReserveActivity>[];
