import {useWatch} from 'react-hook-form';
import {AssigneeType, getAssigneeUid, TicketStatus} from '../../..';
import {useUserInfo} from '../../../hooks';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_AUTHOR, TicketStatusRules} from '../constants/constants';
import {useIsEditingClosed} from './useIsEditingClosed';
import {useTicketStatus} from './useTicketStatus';
import {useUserDepartment} from './useUserDepartment';

export function useIsEditingDisabled() {
  const {isEditingClosed} = useIsEditingClosed();
  const {uid, isErpAdmin, isAgentAdmin, isPortal, userGroupId, isAgentMember} = useUserInfo();
  const {department} = useUserDepartment();
  const {status} = useTicketStatus();
  const assignee = useWatch({name: FIELD_NAME_ASSIGNEE});
  const author = useWatch({name: FIELD_NAME_AUTHOR});
  const statusOptions: TicketStatus[] = status ? TicketStatusRules[status as TicketStatus] : [];

  const isUserAuthor = author && author.id === uid;

  const isUserAssignee = assignee && getAssigneeUid(assignee) === uid;

  const isErpUserAssignee = assignee?.erpUser && uid === assignee.erpUser.id;

  const isErpDepartmentAssignee =
    assignee?.type === AssigneeType.erpDepartment && assignee?.erpDepartment === department;

  const isErpAssignee = isErpUserAssignee || isErpDepartmentAssignee;

  const isEditingDisabled =
    isEditingClosed ||
    !(status === TicketStatus.Draft || isErpAssignee || isErpAdmin || isAgentAdmin);

  let canAgentUpdateStatusToResolved = false;
  if (
    isPortal &&
    assignee?.group?.id === userGroupId &&
    assignee?.type &&
    [AssigneeType.agent, AssigneeType.merchant].includes(assignee.type) &&
    statusOptions.includes(TicketStatus.Closed)
  ) {
    if (isAgentAdmin) {
      //The Group Admin Agent can Resolve any tickets Assigned to themself, one of their Merchants or their Agents.
      canAgentUpdateStatusToResolved = true;
    } else if (
      isAgentMember &&
      assignee.type === AssigneeType.agent &&
      assignee.agent?.id === uid
    ) {
      //The Assigned Agent can Resolve the ticket if they feel no further action is needed
      canAgentUpdateStatusToResolved = true;
    }
  }

  const isEditingDisabledStatus =
    !isUserAssignee &&
    !isErpAdmin &&
    !(
      isUserAuthor &&
      status &&
      [TicketStatus.Closed, TicketStatus.Unresolved].includes(status) &&
      !isPortal
    ) &&
    !canAgentUpdateStatusToResolved;

  const isStatusChangeLocked = assignee?.type === AssigneeType.erpDepartment;

  return {
    isEditingDisabled,
    isEditingDisabledStatus,
    isStatusChangeLocked,
    canAgentUpdateStatusToResolved,
  };
}
