import {useCallback} from 'react';
import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {
  AssigneeType,
  Collections,
  Firebase,
  selectTicketView,
  Ticket,
  TicketHistoryRecordType,
  TicketStatus,
  TicketStatusName,
  TicketView,
  UniversalSnapshot,
  UserRoles,
  useUserInfo,
} from '../../..';
import {autoUpdateTicketStatus} from '../../../helpers';
import {useHistoryRecord} from './useHistoryRecord';

export const useCommentOrAttachmentLogic = () => {
  const {addHistoryRecord} = useHistoryRecord();
  const {role: currentUserRole} = useUserInfo();

  const getIsTicketAllowedToAutoReply = (
    ticket: TicketView,
    currentUserRole: UserRoles | undefined
  ) => {
    if (!currentUserRole || ![UserRoles.merchant, UserRoles.agent].includes(currentUserRole)) {
      return false;
    }
    return (
      ticket.assignee?.type !== AssigneeType.erpDepartment &&
      ticket.assignee?.type !== AssigneeType.erpUser &&
      ticket.status !== TicketStatus.Closed
    );
  };

  const getIsTicketAllowedToNew = (ticket: TicketView, currentUserRole: UserRoles | undefined) => {
    if (!currentUserRole || ![UserRoles.merchant, UserRoles.agent].includes(currentUserRole)) {
      return false;
    }
    return ticket.status === TicketStatus.Closed;
  };

  const handleAutoStatusUpdate = useCallback(
    async (
      ticketView: TicketView,
      nextTicketStatus: TicketStatus,
      setValue?: UseFormSetValue<FieldValues>
    ) => {
      const ticketRef = Firebase.firestore.collection(Collections.tickets).doc(ticketView.id);

      const {nextAssignee} = await autoUpdateTicketStatus(ticketRef, nextTicketStatus, setValue);

      await addHistoryRecord(
        `Status Change to ${TicketStatusName[nextTicketStatus]}`,
        TicketHistoryRecordType.Status,
        false,
        `Status Change to ${TicketStatusName[nextTicketStatus]}`
      );
      await addHistoryRecord(
        `Assignee auto-changed to ${nextAssignee?.erpDepartment ?? 'n/a'}`,
        TicketHistoryRecordType.Assignee,
        false,
        `Assignee auto-changed to ${nextAssignee?.erpDepartment ?? 'n/a'}`
      );
    },
    [currentUserRole, addHistoryRecord]
  );

  const handleAutoReply = useCallback(
    async (ticketView: TicketView, setValue?: UseFormSetValue<FieldValues>) => {
      const isTicketAllowedToAutoReply = getIsTicketAllowedToAutoReply(ticketView, currentUserRole);
      if (!isTicketAllowedToAutoReply) {
        return;
      }

      await handleAutoStatusUpdate(ticketView, TicketStatus.Replied, setValue);
    },
    [currentUserRole, handleAutoStatusUpdate]
  );

  const handleResolvedTickets = useCallback(
    async (ticketView: TicketView, setValue?: UseFormSetValue<FieldValues>) => {
      const isTicketAllowedToNew = getIsTicketAllowedToNew(ticketView, currentUserRole);
      if (!isTicketAllowedToNew) {
        return;
      }

      await handleAutoStatusUpdate(ticketView, TicketStatus.Open, setValue);
    },
    [currentUserRole, addHistoryRecord]
  );

  const handleChanges = useCallback(
    async (ticketId: string, setValue?: UseFormSetValue<FieldValues>) => {
      if (!currentUserRole || ![UserRoles.merchant, UserRoles.agent].includes(currentUserRole)) {
        return false;
      }
      const ticketRef = Firebase.firestore.collection(Collections.tickets).doc(ticketId);
      const ticketSnap = await ticketRef.get();
      const ticketView = selectTicketView(ticketSnap as UniversalSnapshot<Ticket>);

      await handleAutoReply(ticketView, setValue);
      await handleResolvedTickets(ticketView, setValue);
    },
    [currentUserRole, handleAutoReply, handleResolvedTickets]
  );

  return {
    handleCommentOrAttachmentChanges: handleChanges,
  };
};
