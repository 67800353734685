import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import {SxProps} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  ApplicationView,
  Collections,
  createAuditNonce,
  Dispositions,
  Firebase,
  getColor,
  PdfDocuments,
  useApplicationResume,
  useCallable,
  useDocumentsUploadLink,
  useNotification,
} from '@ozark/common';
import {
  CloneDialog,
  ConfirmationDialog,
  DisputeReasonsDetail,
  ResendDisputeEmailsDialog,
  TransferToMerchantConfirmationDialog,
} from '@ozark/common/components';
import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useStore} from '../../../store/helpers';

const sx: Record<string, SxProps> = {
  paper: {
    marginTop: 0,
    paddingTop: 1,
    paddingX: 2,
    paddingBottom: 2,
    position: 'relative',
    borderTop: 'solid 4px',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 1,
    paddingX: 0,
    paddingBottom: 0,
  },
  actions: {
    flexGrow: `0 !important` as any,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    paddingRight: 1,
    '& > *': {
      marginY: 0,
      marginX: 0.5,
    },
  },
  grow: {
    flexGrow: 1,
  },
} as const;

type BarProps = {
  application: ApplicationView;
};

export const Bar = ({application}: BarProps) => {
  const {isUserAgent, authProfile, group} = useStore();
  const showNotification = useNotification();
  const history = useHistory();
  const {resumeApplication} = useApplicationResume();
  const {copyUploadLink} = useDocumentsUploadLink();
  const {downloadPdf} = useCallable();

  const [isMpaDownloading, setIsMpaDownloading] = useState(false);
  const [pendingReasonsMenuEl, setPendingReasonsMenuEl] = useState<null | HTMLElement>(null);
  const [disputeReasonsVisible, setDisputeReasonsVisible] = useState(false);
  const [resendDisputeEmailsVisible, setResendDisputeEmailsVisible] = useState(false);
  const [transferToMerchantDialogOpen, setTransferToMerchantDialogOpen] = useState<boolean>(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const [deleted, setDeleted] = useState<Boolean>(application.deleted || false);

  const disableOnlineApplications = authProfile?.data?.disableOnlineApplications ?? false;
  const enableMpaSharingWithAgents =
    group?.data?.applicationSettings?.enableMpaSharingWithAgents ?? false;

  const getDeleteActionName = () => (deleted ? '' : 'delete');

  const handleOpenPendingReasonsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPendingReasonsMenuEl(event.currentTarget);
  };

  const downloadAgentMpa = useCallback(async () => {
    if (!isUserAgent()) {
      return;
    }

    if (!group?.data?.name) {
      return;
    }

    setIsMpaDownloading(true);

    const fileName = `Merchant Processing Agreement_(${group.data.name}).pdf`;

    try {
      const result = await downloadPdf({
        applicationId: application.id,
        pdfDocument: PdfDocuments.EvolveTSYSAgents,
        fileName,
      });

      if (result.status !== 'ok') {
        throw new Error('No download URL returned');
      }

      let a = document.createElement('a');
      a.href = result.downloadUrl;
      a.setAttribute('target', '_blank');
      a.setAttribute('download', `evolve-${application.id}.pdf`);
      a.click();
    } catch (err) {
      console.error('Failed to download MPA', err);
      showNotification('error', 'Failed to download MPA');
    } finally {
      setIsMpaDownloading(false);
    }
  }, [group?.data?.name, application.id, downloadPdf, isUserAgent, showNotification]);

  const handleDeleteApplication = () => async () => {
    try {
      if (application.deleted) {
        return;
      }
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const nextIsDeleted = !application.deleted;
      const data = {deleted: nextIsDeleted, auditNonce};

      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true}).then(() => setDeleted(data.deleted));
      }
      history.goBack();
      showNotification('success', `Application has been deleted.`);
      setConfirmationAction(null);
    } catch (err: any) {
      console.error(`failed to ${getDeleteActionName()} document. ${err.toString()}`);
      showNotification('error', `Error deleting application.`);
    }
  };

  const respondPendingReasons = async () => {
    const link = await copyUploadLink(
      application.id,
      application.disposition,
      'agent',
      authProfile?.data?.id
    );

    if (link === undefined) {
      showNotification('error', 'Failed to get documents upload token');
      return;
    }

    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <>
      <Paper
        sx={sx.paper}
        style={{
          borderTopColor: getColor(
            application.disposition || Dispositions.incomplete,
            application.isClosed,
            application.uwUploadedAt
          ),
        }}
        square
      >
        <Box sx={sx.heading}>
          <Typography variant="body1">
            <strong>
              {application.legalBusinessName} &bull; {application.doingBusinessAs}
            </strong>
            {application.isClosed && (
              <span>
                {' '}
                (Closed{' '}
                <Tooltip title="This account is closed" sx={{verticalAlign: 'middle'}}>
                  <InfoIcon color="info" />
                </Tooltip>
                )
              </span>
            )}
          </Typography>

          <Box sx={sx.grow}></Box>

          <Box sx={sx.actions}>
            {application.disposition === Dispositions.uwPending &&
              isUserAgent() &&
              !disableOnlineApplications && (
                <Button onClick={handleOpenPendingReasonsMenu}>Pending Reasons</Button>
              )}
            {application.disposition === Dispositions.incomplete &&
              !application.complete &&
              !disableOnlineApplications && (
                <Button
                  variant="outlined"
                  onClick={() => resumeApplication(application.id, application.group.id)}
                >
                  Resume
                </Button>
              )}
            {application.disposition === Dispositions.incomplete && !disableOnlineApplications && (
              <Button variant="outlined" onClick={() => setTransferToMerchantDialogOpen(true)}>
                Transfer to Merchant
              </Button>
            )}

            {isUserAgent() &&
              application.disposition === Dispositions.boarded &&
              enableMpaSharingWithAgents && (
                <Button
                  disabled={isMpaDownloading}
                  sx={{position: 'relative'}}
                  variant="outlined"
                  onClick={downloadAgentMpa}
                >
                  Download MPA
                  {isMpaDownloading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Button>
              )}

            {isUserAgent() && !disableOnlineApplications && (
              <Button variant="outlined" onClick={() => setCloneDialogOpen(true)}>
                Clone Application
              </Button>
            )}

            {isUserAgent() &&
              !disableOnlineApplications &&
              !application.deleted &&
              application.disposition === Dispositions.incomplete && (
                <Button
                  variant="outlined"
                  onClick={() => setConfirmationAction(() => handleDeleteApplication())}
                >
                  Delete
                </Button>
              )}
            <Menu
              id="pending-reasons"
              anchorEl={pendingReasonsMenuEl}
              onClose={() => setPendingReasonsMenuEl(null)}
              keepMounted
              open={Boolean(pendingReasonsMenuEl)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => setDisputeReasonsVisible(true)}>
                <Typography>View Pending Reasons</Typography>
              </MenuItem>
              <MenuItem onClick={async () => await respondPendingReasons()}>
                <Typography>Respond to Pending Reasons</Typography>
              </MenuItem>
              <MenuItem onClick={() => setResendDisputeEmailsVisible(true)}>
                <Typography>Resend Pending Emails</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Paper>
      {disputeReasonsVisible && (
        <DisputeReasonsDetail
          applicationId={application.id}
          disposition={application.disposition}
          setDialogVisible={setDisputeReasonsVisible}
        />
      )}
      {resendDisputeEmailsVisible && (
        <ResendDisputeEmailsDialog
          application={application}
          setDialogVisible={setResendDisputeEmailsVisible}
        />
      )}
      {transferToMerchantDialogOpen && (
        <TransferToMerchantConfirmationDialog
          application={application}
          onClose={() => setTransferToMerchantDialogOpen(false)}
          errorMessage="Please enter Equipment, Processing Info, Rate Set and Business Type before transferring application to a merchant"
        />
      )}
      {cloneDialogOpen && (
        <CloneDialog
          route={ROUTES.APPLICATION_PATH}
          applicationId={application.id}
          onClose={() => setCloneDialogOpen(false)}
          referrer="all"
          hidePlatform
          defaultPlatform={application.platform}
        />
      )}
      {confirmationAction && (
        <ConfirmationDialog
          title="Confirmation"
          message={`Are you sure you want to ${getDeleteActionName()}?`}
          onClose={() => setConfirmationAction(null)}
          onConfirm={confirmationAction}
        />
      )}{' '}
    </>
  );
};
