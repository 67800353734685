import {
  AsyncState,
  Collections,
  Firebase,
  Resource,
  RESOURCE_CATEGORY_ERP,
  ResourceView,
  UniversalSnapshot,
} from '@ozark/common';
import {
  ResourceDocumentCategory,
  ResourceDocumentCategoryView,
  selectResourceDocumentCategoryView,
} from '@ozark/functions/src/documents';
import {useCallback, useEffect, useState} from 'react';

type UseResourcesProps = {
  groupId?: string;
};

export const useResources = ({groupId}: UseResourcesProps = {}) => {
  const [resources, setResources] = useState<AsyncState<ResourceView[]>>({
    promised: true,
  });
  const [documentCategories, setDocumentCategories] = useState<
    AsyncState<ResourceDocumentCategoryView[]>
  >({promised: true});

  const [allDocumentCategories, setAllDocumentCategories] = useState<
    AsyncState<ResourceDocumentCategoryView[]>
  >({promised: true});

  const fetchResources = useCallback(async () => {
    Firebase.firestore
      .collection(Collections.resources)
      .where('deleted', '!=', true)
      .orderBy('deleted', 'asc')
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setResources({promised: false, data: []});
            return;
          }

          const resources = await Promise.all(
            snapshot.docs.map(async snap => {
              const data = snap.data() as Resource;
              let downloadPreviewUrl = data.downloadPreviewUrl ?? data.downloadUrl;
              // update DownloadPreviewUrl if needed:
              if (
                data.cloudPreviewImagePath &&
                data.cloudPath !== data.cloudPreviewImagePath &&
                !data.downloadPreviewUrl &&
                data.previewCalculated !== true
              ) {
                try {
                  const storageRef = Firebase.storage.ref(data.cloudPreviewImagePath);
                  const downloadUrl = await storageRef.getDownloadURL();
                  await snap.ref.update({downloadPreviewUrl: downloadUrl, previewCalculated: true});
                  downloadPreviewUrl = downloadUrl;
                } catch {
                  // if preview file doesn't exist
                  try {
                    await snap.ref.update({previewCalculated: true});
                  } catch {
                    //catch update exception too
                  }
                }
              }

              return {
                ...data,
                downloadPreviewUrl,
                id: snap.id,
                ref: snap,
              } as ResourceView;
            })
          );

          setResources({promised: false, data: resources});
          return;
        },
        err => {
          console.error(err);
          setResources({promised: false, error: err});
          return;
        }
      );
  }, []);

  const fetchDocumentCategories = useCallback(async () => {
    Firebase.firestore
      .collection(Collections.resourceDocumentCategories)
      .where('deleted', '!=', true)
      .onSnapshot(async snapshot => {
        try {
          if (snapshot.size === 0) {
            setDocumentCategories({promised: false, data: []});
            setAllDocumentCategories({promised: false, data: []});
            return;
          }

          const allResourceCategories = snapshot.docs.map(doc =>
            selectResourceDocumentCategoryView(doc as UniversalSnapshot<ResourceDocumentCategory>)
          );

          setAllDocumentCategories({promised: false, data: allResourceCategories});

          const resourceCategories = allResourceCategories.filter(
            x => x.groupId === (groupId ?? RESOURCE_CATEGORY_ERP)
          );

          setDocumentCategories({promised: false, data: resourceCategories});
        } catch (err: any) {
          console.error(err);
          setDocumentCategories({promised: false, error: err});
          setAllDocumentCategories({promised: false, error: err});
        }
      });
  }, []);

  useEffect(() => {
    fetchResources();
    fetchDocumentCategories();
  }, [fetchResources, fetchDocumentCategories]);

  return {resources, documentCategories, allDocumentCategories};
};
