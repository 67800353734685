import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  AgentView,
  CallableFunctionName,
  ExportToCsv,
  Firebase,
  GroupRole,
  PublishedResidualOptionsResults,
  SearchCriteria,
  useCallable,
  UserRoles,
} from '@ozark/common';
import {
  ActiveFilter,
  AutoCompleteInputBase,
  Filters,
  Loading,
  Title,
} from '@ozark/common/components';
import {ResidualGroupAdjustments} from '@ozark/functions/src/functions/callable/dispatch_GetResidualGroupAdjustments';
import {
  GroupAgentResponse,
  GroupResidual,
  PaginatedGroupAgentResponse,
} from '@ozark/functions/src/functions/express/private/types/Residual';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {useStore} from '../../store/helpers';
import {hasResidualsPermissions} from '../../utils/hasResidualsPermissions';
import {forceActiveFilter, useReportingPageStyles} from '../ReportingPage';
import {AgentAdjustments} from './Group/AgentAdjustments';
import {AgentAggregateResidualsTable} from './Group/AgentAggregateResidualsTable';
import {DetailedResidualsTable} from './Group/DetailedResidualsTable';
import {GroupAdjustments} from './Group/GroupAdjustments';
import {GroupFilters} from './Group/GroupFilters';
import {GroupTiles} from './Group/GroupTiles';
import {DetailedResidualsFilters} from './Group/residualsFilters';
import {AgentPayoutsToDisplay, DetailedDataRow, GroupResidualsView} from './Group/types';
import {formatYearMonth} from './utils';

export const DefaultCriteria: SearchCriteria = {
  limit: 10, // page size
  offset: 1, // page
  order: 'asc',
  orderBy: 'yearMonth',
};

const VIEW_LOCAL_STORAGE_KEY = 'groupResidualsView';

const getFullName = (agent: AgentView) => `${agent.firstName || ''} ${agent.lastName || ''}`.trim();

const agentNameComparer = (a: AgentView, b: AgentView) =>
  getFullName(a).localeCompare(getFullName(b));

const getDropDownTitle = (agent?: AgentView): string => {
  return agent ? getFullName(agent) : 'All Agents';
};

const ResidualsGroupPage = () => {
  const [loadingResiduals, setLoadingResiduals] = useState(true);
  const classes = useReportingPageStyles();
  const {apiClient, authProfile, claims, getAuthorizedAgents} = useStore();
  const [loadingAgentResiduals, setLoadingAgentResiduals] = useState(true);
  const [detailedResiduals, setDetailedResiduals] = useState<DetailedDataRow | null>(null);
  const [agentAggregateResiduals, setAgentAggregateResiduals] =
    useState<PaginatedGroupAgentResponse | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<{[_: string]: ActiveFilter}>({});
  const [yearMonths, setYearMonths] = useState<string[] | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>();
  const [agentPayouts, setAgentPayouts] = useState<AgentPayoutsToDisplay[] | null>(null);
  const [groupPayouts, setGroupPayouts] = useState<ResidualGroupAdjustments[] | null>(null);
  const [agentPayoutAdj, setAgentPayoutAdj] = useState(0);
  const [groupPayoutAdj, setGroupPayoutAdj] = useState(0);
  const [width, setWidth] = useState<any>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<GroupResidualsView>(
    (localStorage.getItem(VIEW_LOCAL_STORAGE_KEY) as GroupResidualsView) ?? 'agent_aggregate'
  );
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const [selectOptions, setSelectOptions] = useState<AgentView[]>([]);

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      setSelectOptions(agents ?? []);
    });
  }, [getAuthorizedAgents]);

  const isAgentAggregateView = useMemo(() => view === 'agent_aggregate', [view]);
  const isDetailedView = useMemo(() => view === 'detailed', [view]);

  const onViewChange = (value: GroupResidualsView) => {
    setView(value);
    localStorage.setItem(VIEW_LOCAL_STORAGE_KEY, value);
    const {yearMonth} = filters;
    setFilters({yearMonth: yearMonth});
  };

  const {getResidualAdjustments, getResidualGroupAdjustments} = useCallable();

  // check permissions
  const history = useHistory();
  useEffect(() => {
    // same permissions as navigator config isVisible: ...
    if (
      claims?.role !== UserRoles.agent ||
      claims?.groupRole !== GroupRole.administrator ||
      !hasResidualsPermissions(authProfile?.data)
    ) {
      history.push('/');
    }
  }, [claims?.role, claims?.groupRole, authProfile?.data, history]);

  useEffect(() => {
    if (!selectedYearMonth) {
      setAgentPayouts(null);
      setGroupPayouts(null);
      return;
    }

    setLoadingAgentResiduals(true);
    getResidualAdjustments({
      yearMonth: selectedYearMonth,
      agentId: selectedAgent?.id,
      appendManualAdjustments: false,
    })
      .then(result => {
        if (result.status === 'ok') {
          setAgentPayouts(result.data);
        }
      })
      .finally(() => {
        setLoadingAgentResiduals(false);
      });

    getResidualGroupAdjustments({
      yearMonth: selectedYearMonth,
    }).then(result => {
      if (result.status === 'ok') {
        setGroupPayouts(result.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearMonths, selectedYearMonth, selectedAgent?.id]);

  useEffect(() => {
    if (!agentPayouts) {
      setAgentPayoutAdj(0);
      return;
    }

    const nextPayoutAdj = agentPayouts.reduce(
      (previousValue, current) => previousValue + current.amount,
      0
    );

    setAgentPayoutAdj(nextPayoutAdj);
  }, [agentPayouts]);

  useEffect(() => {
    if (!groupPayouts) {
      setGroupPayoutAdj(0);
      return;
    }

    const nextPayoutAdj = groupPayouts
      .map(x => x.lineItems)
      .flatMap(x => x)
      .reduce((previousValue, current) => previousValue + current.amount, 0);

    setGroupPayoutAdj(nextPayoutAdj);
  }, [groupPayouts]);

  useLayoutEffect(() => {
    if (loadingAgentResiduals || loadingResiduals) return;
    setTimeout(() => {
      if (ref?.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, 200);
  }, [loadingAgentResiduals, loadingResiduals]);

  useEffect(() => {
    (async () => {
      const getPublishedResidualOptions = Firebase.functions.httpsCallable(
        CallableFunctionName.getPublishedResidualOptions
      );
      const result: {data: PublishedResidualOptionsResults} = await getPublishedResidualOptions({});

      if (!result?.data?.options) {
        setLoadingResiduals(false);
        return;
      }

      if (result?.data?.options) {
        const options = result.data.options.sort();
        setSelectedYearMonth(options[options.length - 1]);
        setYearMonths(options);
        if (!options.length) {
          setLoadingResiduals(false);
        }
        const forcedFilter = forceActiveFilter(
          DetailedResidualsFilters,
          'yearMonth',
          '__eq',
          options[options.length - 1]
        );
        setFilters({...filters, yearMonth: forcedFilter});
      }
    })();
    // eslint-disable-next-line
  }, []);

  const getDetailedResiduals = () => {
    if (!isDetailedView) {
      setDetailedResiduals(null);
      return;
    }
    setLoadingResiduals(true);
    apiClient.residuals
      .getGroupResiduals(searchCriteria, Object.values(filters), selectedAgent?.id)
      .then((result: any) => {
        setDetailedResiduals(result || {});
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoadingResiduals(false);
      });
  };

  const getAgentAggregateResiduals = () => {
    if (!isAgentAggregateView) {
      setAgentAggregateResiduals(null);
      return;
    }
    setLoadingResiduals(true);
    apiClient.residuals
      .getGroupAgentAggregateResiduals(searchCriteria, Object.values(filters), selectedAgent?.id)
      .then((result: PaginatedGroupAgentResponse | null) => {
        setAgentAggregateResiduals(result);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoadingResiduals(false);
      });
  };

  const exportToCsv = async () => {
    const options = {
      fieldSeparator: ',',
      filename: `residuals-${selectedYearMonth}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Residuals',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exportData: GroupResidual[] | GroupAgentResponse[] | null = isDetailedView
      ? await apiClient.residuals.getGroupResidualsExport(
          searchCriteria,
          Object.values(filters),
          selectedAgent?.id
        )
      : await apiClient.residuals.getGroupAgentAggregateResidualsExport(
          searchCriteria,
          Object.values(filters),
          selectedAgent?.id
        );

    if (!exportData) return;

    const exporter = new ExportToCsv(options);
    if (isAgentAggregateView) {
      for (const row of exportData) {
        const groupAgentResponseRow = row as GroupAgentResponse;
        const averageTicket = groupAgentResponseRow.averageTicket;
        if (!averageTicket) {
          continue;
        }
        groupAgentResponseRow.averageTicket = (
          Math.round((+averageTicket + Number.EPSILON) * 100) / 100
        ).toString();
      }
    }

    const noNullData = JSON.parse(JSON.stringify(exportData).replace(/null/gi, '""'));

    exporter.generateCsv(noNullData);
  };

  useEffect(() => {
    if (!selectedYearMonth) return;
    getDetailedResiduals();
    getAgentAggregateResiduals();
    // eslint-disable-next-line
  }, [searchCriteria, filters, view, selectedAgent?.id]);

  const handleRetrieveData = useCallback((searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  }, []);

  const handleDateChange = (event: any) => {
    const yearMonth = event.target.value;
    setSelectedYearMonth(yearMonth);
    const forcedFilter = forceActiveFilter(
      DetailedResidualsFilters,
      'yearMonth',
      '__eq',
      yearMonth
    );
    setFilters({...filters, yearMonth: forcedFilter});
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  const handleDeleteFilter = useCallback(
    (id: string) => () => {
      const _filters = {...filters};
      delete _filters[id];
      setFilters(_filters);
    },
    [filters]
  );

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Group Residuals</Typography>];
  };

  const setSelectedAgentWithChangeValidation = (agent?: AgentView) => {
    setSelectedAgent(agent);
  };

  if (authProfile.promised) return <Loading />;

  if (loadingResiduals) {
    return <Loading />;
  }

  if (!yearMonths || yearMonths.length === 0) {
    return (
      <Box mt={6}>
        <Typography variant="body1" align="center">
          No residuals have been added.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root} ref={ref}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <ToggleButtonGroup
          value={view}
          onChange={(_, newView) => newView && onViewChange(newView)}
          exclusive
          aria-label="Content view"
          color="primary"
          size="small"
          sx={{pt: 0.5}}
        >
          <ToggleButton value="agent_aggregate">Agent Aggregate View</ToggleButton>
          <ToggleButton value="detailed">MID View</ToggleButton>
        </ToggleButtonGroup>
        <div className={classes.grow} />

        <AutoCompleteInputBase
          selected={selectedAgent}
          setSelected={setSelectedAgentWithChangeValidation}
          icon
          placeholder="Select agent..."
          options={selectOptions}
          comparer={agentNameComparer}
          getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
          onItemSelect={(agent: AgentView | null) =>
            setSelectedAgentWithChangeValidation(agent ?? undefined)
          }
        />

        <Divider orientation="vertical" className={classes.divider} flexItem />

        {yearMonths && (
          <TextField
            value={selectedYearMonth}
            onChange={handleDateChange}
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {yearMonths.map(e => {
              return (
                <MenuItem key={e} value={e}>
                  {formatYearMonth(e)}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <Divider orientation="vertical" className={classes.divider} flexItem />
        {isDetailedView && (
          <>
            <Filters options={DetailedResidualsFilters} onApplyFilter={handleApplyFilter} />
            <Divider orientation="vertical" className={classes.divider} flexItem />
          </>
        )}
        <Button onClick={exportToCsv}>Export</Button>
      </Title>

      {((isDetailedView && detailedResiduals && isEmpty(detailedResiduals)) ||
        (isAgentAggregateView && agentAggregateResiduals && isEmpty(agentAggregateResiduals))) && (
        <Typography className={classes.noContent}>No Residuals</Typography>
      )}
      {((isDetailedView && detailedResiduals && !isEmpty(detailedResiduals)) ||
        (isAgentAggregateView && agentAggregateResiduals && !isEmpty(agentAggregateResiduals))) && (
        <>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <GroupFilters filters={filters} handleDeleteFilter={handleDeleteFilter} />
            <GroupTiles
              residualTiles={isDetailedView ? detailedResiduals : agentAggregateResiduals}
              groupPayoutAdj={groupPayoutAdj}
            />
          </Grid>
          <Box
            component={Paper}
            overflow="auto"
            mt={2}
            sx={[{'& > div:first-child': {width: '100%'}}, {width: {width}}]}
          >
            {isDetailedView && detailedResiduals && (
              <DetailedResidualsTable
                residuals={detailedResiduals}
                handleRetrieveData={handleRetrieveData}
              />
            )}
            {isAgentAggregateView && agentAggregateResiduals && (
              <AgentAggregateResidualsTable
                paginatedGroupAgentResponse={agentAggregateResiduals}
                handleRetrieveData={handleRetrieveData}
                selectedYearMonth={selectedYearMonth}
              />
            )}

            <GroupAdjustments groupPayouts={groupPayouts} groupPayoutAdj={groupPayoutAdj} />
            <AgentAdjustments agentPayouts={agentPayouts} agentPayoutAdj={agentPayoutAdj} />
          </Box>
        </>
      )}
    </div>
  );
};

export default ResidualsGroupPage;
