import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Divider, IconButton, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {ApplicationSubCollection, Dispositions} from '@ozark/common';
import {ApplicationTickets, Loading} from '@ozark/common/components';
import {ApplicationEdit, PricingEquipment} from '@ozark/common/components/Application';
import {APPLICATION_ANALYTICS_DETAILS_REFERRER} from '@ozark/common/constants/application';
import {useApplicationView, useUserInfo} from '@ozark/common/hooks';
import {ApplicationNotes} from '@ozark/crm/src/components/Application/Notes/Notes';
import React, {useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router';
import {useCallable} from '../../hooks/useCallable';
import {useStore} from '../../store/helpers';
import {ApplicationAttachments} from '../Attachments/ApplicationAttachments';
import {Tab, Tabs} from '../Tabs';
import {Title} from '../Title';
import {Overview} from './Overview';

const TabDivider = (): JSX.Element => <Divider sx={{mx: 0, my: 1}} />;

enum PortalApplicationTab {
  Overview,
  AppPages,
  PricingEquipment,
  SupportNotes,
  Tickets,
  Attachments,
}

const Application = () => {
  const {authUser, isAllowedToEditApplication} = useStore();
  const history = useHistory<{referrer: string}>();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const {id} = useParams<{id: string}>();
  const {application} = useApplicationView(id);
  const [tab, setTab] = useState<PortalApplicationTab>(PortalApplicationTab.Overview);
  const [token, setToken] = useState<string | null>();
  const {getPortalToken} = useCallable();
  const {isAgent} = useUserInfo();

  const [readonly, setReadonly] = useState<boolean>(true);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (!application.data) {
      return;
    }

    getPortalToken({applicationId: application.data!.id}).then(result => {
      if (!result || result.status !== 'ok' || !result.token) return;
      setToken(result.token);
    });

    const isReadonly = !isAllowedToEditApplication(application.data);
    setReadonly(isReadonly);

    // eslint-disable-next-line
  }, [application]);

  if (application.promised) return <Loading />;

  if (!application.data) return <Redirect to="/" />;

  const isIncomplete = application.data.disposition === Dispositions.incomplete;
  const onLinkClick = () => {
    const referrerUrl = history.location?.state?.referrer?.toLowerCase()?.replaceAll(' ', '-');
    if (history.location?.state?.referrer === APPLICATION_ANALYTICS_DETAILS_REFERRER) {
      const {referrer, ...other} = history.location.state;
      history.push(`../${referrerUrl}`, {...other, referrer: ''});
      return;
    }

    history.location?.state?.referrer
      ? history.push(`../applications/${referrerUrl}`)
      : history.goBack();
  };

  return (
    <div>
      <Title
        titleText="Application"
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link component="button" variant="body1" onClick={onLinkClick}>
                  {history.location?.state?.referrer === 'all'
                    ? 'Applications'
                    : history.location?.state?.referrer || 'Applications'}
                </Link>,
                <Typography variant="body1">{application.data.legalBusinessName}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Overview" value={PortalApplicationTab.Overview} />

          {!isIncomplete && [
            <TabDivider />,

            <Tab label="App Pages" value={PortalApplicationTab.AppPages} />,

            <TabDivider />,

            <Tab label="Pricing & Equipment" value={PortalApplicationTab.PricingEquipment} />,
          ]}

          {isAgent && <Tab label="Attachments" value={PortalApplicationTab.Attachments} />}

          {/* Support Notes should be available for boarded application; only agent users can see them */}
          {isAgent &&
            application.data?.disposition === Dispositions.boarded &&
            !application.data.isConditionallyApproved && [
              <TabDivider key="divider-before-support-notes" />,
              <Tab
                key="support-notes"
                label="Support Notes"
                value={PortalApplicationTab.SupportNotes}
              />,
            ]}

          {/* ApplicationTickets should be available for boarded application; only agent users can see them */}
          {isAgent &&
            application.data?.disposition === Dispositions.boarded &&
            !application.data.isConditionallyApproved && [
              <TabDivider key="divider-before-tickets-tab" />,
              <Tab key="tickets-tab" label="Tickets" value={PortalApplicationTab.Tickets} />,
            ]}
        </Tabs>
      </Title>

      {tab === PortalApplicationTab.Overview && <Overview application={application.data} />}

      {tab === PortalApplicationTab.AppPages && (
        <ApplicationEdit application={application.data} token={token} />
      )}

      {tab === PortalApplicationTab.PricingEquipment && (
        <PricingEquipment application={application.data} authUser={authUser} readonly={readonly} />
      )}

      {tab === PortalApplicationTab.Attachments && (
        <ApplicationAttachments application={application.data} />
      )}

      {tab === PortalApplicationTab.SupportNotes && (
        <ApplicationNotes
          applicationId={id}
          notesSubCollection={ApplicationSubCollection.supportNotes}
        />
      )}

      {tab === PortalApplicationTab.Tickets && (
        <ApplicationTickets application={application.data} />
      )}
    </div>
  );
};

export default Application;
