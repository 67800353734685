import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {
  Collections,
  Firebase,
  useApiClient,
  useAuthContainer,
  useLoadingContainer,
  useNotification,
} from '../../..';
import {useCommentOrAttachmentLogic} from './useCommentOrAttachmentLogic';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';

// we need to create a simple CRUD for a comment subcollection with no real uploads - just writing stuff

export function useCommentAttachments() {
  const {setValue} = useFormContext();
  const {authUser} = useAuthContainer();
  const {ticketId} = useTicketId();
  const {apiClient} = useApiClient(authUser);
  const {commentsCollection} = useTicketEditContainer();
  const {setIsLoading} = useLoadingContainer();
  const showNotification = useNotification();
  const {handleCommentOrAttachmentChanges} = useCommentOrAttachmentLogic();

  const attachmentUpload = useCallback(
    async (commentId?: string, attachments?: TmpAttachment[]) => {
      setIsLoading(true);

      try {
        if (!ticketId || !commentId || !attachments) {
          throw new Error('attachmentUpload: invalid arguments');
        }

        await handleCommentOrAttachmentChanges(ticketId, setValue);

        await apiClient.attachments.createAttachments({
          baseCloudPath: `tickets/${ticketId}`,
          cloudPathField: 'fileCloudPath',
          author: {
            uid: authUser.data?.uid ?? '',
            name: authUser.data?.displayName ?? '',
          },
          collection: {
            name: Collections.tickets,
            id: ticketId,
            subcollection: {
              name: commentsCollection,
              id: commentId,
            },
          },
          attachments: attachments.map(attachment => ({
            ...attachment,
            extraFields: {
              fileName: attachment.oldFilename,
            },
          })),
        });

        showNotification('success', 'File successfully uploaded');
      } catch (error) {
        console.error('Error adding document: ', error);
        showNotification('error', 'File upload failed');
      } finally {
        setIsLoading(false);
      }
    },
    [ticketId, commentsCollection]
  );

  const attachmentRemove = useCallback(
    async (commentId?: string, attachmentId?: string) => {
      if (!ticketId || !commentId || !attachmentId) {
        console.log('remove attachment', ticketId, commentId, attachmentId);
        showNotification('error', 'Error removing document (missing params)');
        return;
      }
      try {
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .doc(commentId)
          .collection(Collections.attachments)
          .doc(attachmentId)
          .delete();

        showNotification('success', 'Document successfully deleted!');
      } catch (error) {
        showNotification('error', 'Error removing document');
        console.error('Error removing document: ', error);
      }
    },
    [ticketId, commentsCollection]
  );

  return {
    attachmentUpload,
    attachmentRemove,
  };
}
