import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  SubscriptionCategory,
  SubscriptionEvent,
  SUBSCRIPTIONS,
  SubscriptionType,
} from '@ozark/common';
import {Fragment, useCallback} from 'react';
import {useNotification, useSubscriptionConfig, useUserInfo} from '../../hooks';

// by default all user are subscribed on all emails and portal notifications
const DEFAULT_IS_SUBSCRIBED = true;

const getDefaultValue = (category: SubscriptionType) => {
  if (category === SubscriptionType.ticketUpdated) {
    return false;
  }

  return DEFAULT_IS_SUBSCRIBED;
};

const SubscriptionManagement = () => {
  const {role, groupRole, uid} = useUserInfo();

  const {
    document: {data: subscriptionConfig},
    set: setSubscriptionConfig,
  } = useSubscriptionConfig(uid);

  const showNotification = useNotification();

  const toggleSubscription = useCallback(
    async (
      categoryKey: SubscriptionCategory,
      typeKey: SubscriptionType,
      eventKey: SubscriptionEvent,
      isSubscribed: boolean
    ) => {
      const subscriptionKey = `${categoryKey}:${typeKey}:${eventKey}`;

      try {
        await setSubscriptionConfig({
          [subscriptionKey]: isSubscribed,
        });
        showNotification('success', `Subscription Setting was saved successfully`);
      } catch (error) {
        showNotification('error', 'An error occurred while saving Subscription Settings');
      }
    },
    [setSubscriptionConfig]
  );

  const claims = {role, groupRole};

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Subscription Category</TableCell>
          <TableCell>Emails</TableCell>
          <TableCell>Notifications</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {SUBSCRIPTIONS.map(category => {
          if (!category.isVisible(claims)) {
            return null;
          }

          return (
            <Fragment key={category.key}>
              <TableRow sx={{bgcolor: 'action.hover', position: 'relative'}}>
                <TableCell colSpan={3}>{category.title}</TableCell>
              </TableRow>

              {category.types.map(type => {
                if (!type.isVisible(claims)) {
                  return null;
                }

                return (
                  <TableRow key={`${category.key}-${type.key}`}>
                    <TableCell sx={{pl: '40px'}}>{type.title}</TableCell>

                    {Object.values(SubscriptionEvent).map(eventKey => {
                      if (!type.events[eventKey].isVisible(claims)) {
                        return (
                          <TableCell key={`${category.key}-${type.key}-${eventKey}`}></TableCell>
                        );
                      }

                      return (
                        <TableCell key={`${category.key}-${type.key}-${eventKey}`}>
                          <Switch
                            checked={
                              subscriptionConfig?.[category.key]?.[type.key]?.[eventKey] ??
                              getDefaultValue(type.key)
                            }
                            onChange={(_event, checked) =>
                              toggleSubscription(category.key, type.key, eventKey, checked)
                            }
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export {SubscriptionManagement};
