import {SearchCriteria} from '@ozark/common';
import {Table} from '@ozark/common/components';
import {currentFormatter} from '../../ReportingPage';
import {GroupColumns} from './columns';
import {DetailedDataRow} from './types';

interface Props {
  residuals: DetailedDataRow;
  handleRetrieveData: (searchCriteria: SearchCriteria) => void;
}

export const DetailedResidualsTable = ({residuals, handleRetrieveData}: Props) => {
  return (
    <Table
      noWrap={true}
      columns={GroupColumns}
      data={residuals}
      summary={{
        bankcardSalesVolume: currentFormatter.format(
          parseFloat(residuals?.bankcardSalesVolume || '0')
        ),
        bankcardSalesVolumeAmex: currentFormatter.format(
          parseFloat(residuals?.bankcardSalesVolumeAmex || '0')
        ),
        transactionCount: parseFloat(residuals?.transactionCount || '0'),
        transactionCountAmex: parseFloat(residuals?.transactionCountAmex || '0'),
        totalIncome: currentFormatter.format(parseFloat(residuals?.totalIncome || '0')),
        groupExpense: currentFormatter.format(parseFloat(residuals?.groupExpense || '0')),
        groupNetSplit: currentFormatter.format(parseFloat(residuals?.groupNetSplit || '0')),
        groupNet: currentFormatter.format(parseFloat(residuals?.groupNet || '0')),
        agentExpense: currentFormatter.format(parseFloat(residuals?.agentExpense || '0')),
        agentNet: currentFormatter.format(parseFloat(residuals?.agentNet || '0')),
        agentNetSplit: currentFormatter.format(parseFloat(residuals?.agentNetSplit || '0')),
        groupProfit: currentFormatter.format(parseFloat(residuals?.groupProfit ?? '0')),
        agentProfit: currentFormatter.format(parseFloat(residuals?.agentProfit || '0')),
        subAgentProfit: currentFormatter.format(parseFloat(residuals?.subAgentProfit || '0')),
        shareNetSplit: currentFormatter.format(parseFloat(residuals?.shareNetSplit || '0')),
        groupProfitAdjusted: currentFormatter.format(
          parseFloat((residuals?.groupProfitAdjusted ?? 0).toString())
        ),
      }}
      onRowClick={() => {}}
      onRetrieveData={handleRetrieveData}
      paginate
      stickyHeader
      scrollableBody
    />
  );
};
