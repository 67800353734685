import {yupResolver} from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  MenuItem,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  formatPhone,
  RegisterMerchantUserPayload,
  TimeZone,
  TimeZoneIdsList,
  TimeZoneValues,
  useNotification,
} from '@ozark/common';
import {Copyright, Select, TextField} from '@ozark/common/components';
import {trimWhiteSpace} from '@ozark/common/helpers';
import {useUrlQuery} from '@ozark/common/hooks/useUrlQuery';
import {ValidateMerchantForRegistrationResultPayload} from '@ozark/functions/src/functions/callable/dispatch_ValidateMerchantForRegistration';
import PhoneNumber from 'awesome-phonenumber';
import {subYears} from 'date-fns';
import {format} from 'date-fns-tz';
import {Fragment, useEffect, useState} from 'react';
import {useForm, UseFormSetError} from 'react-hook-form';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import {LOGIN} from '../../constants/routes';
import {useCallable} from '../../hooks/useCallable';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepperRoot: {
    width: '100%',
  },
  logo: {
    margin: theme.spacing(2),
    width: 300,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const schema = yup.object().shape({
  applicationMid: yup
    .string()
    .min(15, 'Must be at least 15 digits')
    .max(16, 'Must be at most 16 digits')
    .required('Application MID is required'),
  dateOfBirth: yup
    .date()
    .required('Date of Birth is required')
    .max(subYears(new Date(), 18), 'Signer must be 18 years or older')
    .typeError('Invalid date'),
  ssnLast4: yup
    .string()
    .length(4, 'Last four digits of Social Security number are required')
    .required('Last four digits of Social Security number are required'),
});

const accountRegistrationSchema = yup.object().shape({
  firstName: yup.string().transform(trimWhiteSpace).required('First name is required'),
  lastName: yup.string().transform(trimWhiteSpace).required('Last name is required'),
  phoneNumber: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Business Phone is required'),
  timeZoneId: yup.string().required('Time zone is required'),
  email: yup.string().email().required('Email address is required'),
  password: yup
    .string()
    /*
      ^                         Start anchor
      (?=.*[A-Z])               Ensure string has one uppercase letter.
      (?=.*[!@#$&*])            Ensure string has one special character.
      (?=.*[0-9])               Ensure string has one digit.
      (?=.*[a-z])               Ensure string has one lowercase letter.
      .{8,}                     Ensure string is at least 8 characters.
      $                         End anchor.
    */
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, one special charachter (!@#$&*), and be at least 8 charachters long'
    )
    .required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const steps: string[] = ['Enter application details', 'Create an account', 'Done'];

function addServerErrors(setError: UseFormSetError<any>, message: string, fields?: string[]) {
  if (fields) {
    fields.forEach(field => {
      setError(field, {
        type: 'server',
        message: message,
      });
    });
  } else {
    console.error('Error fields are not preset, cannot display error message');
  }
}

const MerchantRegistration = () => {
  const {group} = useStore();
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validationMerchantResult, setValidationMerchantResult] =
    useState<ValidateMerchantForRegistrationResultPayload | null>(null);
  const {validateMerchantForRegistration, registerMerchantUser} = useCallable();
  const [merchantAlreadyAssignedModalOpen, setMerchantAlreadyAssignedModalOpen] = useState(false);

  const showNotification = useNotification();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useForm<{
    applicationMid: string;
    dateOfBirth: Date;
    ssnLast4: string;
  }>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  type AccounRegistrationForm = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    timeZoneId: string;
    email: string;
    password: string;
    passwordConfirmation: string;
  };

  const accountRegistrationForm = useForm<AccounRegistrationForm>({
    resolver: yupResolver(accountRegistrationSchema),
  });

  const urlQuery = useUrlQuery();
  useEffect(() => {
    if (urlQuery.has('mid')) {
      setValue('applicationMid', urlQuery.get('mid') as any, {shouldDirty: true});
      // setFocus('dateOfBirth'); only version 7 (now 6.14)
    }
  }, [urlQuery, setValue]);

  const setAccountRegistrationFormDefaults = (
    result: ValidateMerchantForRegistrationResultPayload
  ) => {
    const accountRegistrationFormSetValue = (name: keyof AccounRegistrationForm, value: string) => {
      accountRegistrationForm.setValue(name, value as any, {
        shouldDirty: true,
      });
    };

    accountRegistrationFormSetValue('email', result.email);
    accountRegistrationFormSetValue('firstName', result.firstName);
    accountRegistrationFormSetValue('lastName', result.lastName);
    accountRegistrationFormSetValue('phoneNumber', formatPhone(result.phoneNumber));
    accountRegistrationFormSetValue('timeZoneId', result.timeZoneId);
  };

  const handleVerifyMerchantApp = async (data: {
    applicationMid: string;
    dateOfBirth: Date;
    ssnLast4: string;
  }) => {
    setLoading(true);

    const result = await validateMerchantForRegistration({
      mid: data.applicationMid,
      dateOfBirth: format(data.dateOfBirth, 'MMddyyyy'),
      socialSecurityNumberLast4: data.ssnLast4,
    });

    if (result.status === 'ok') {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setAccountRegistrationFormDefaults(result);

      setValidationMerchantResult(result);
    } else {
      if (result.errorCode === '409') {
        setMerchantAlreadyAssignedModalOpen(true);
      }
      addServerErrors(setError, result.message, result.fields);
    }

    setLoading(false);
  };

  const handleCreateAccount = async (data: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    timeZoneId: string;
    email: string;
    password: string;
  }) => {
    if (validationMerchantResult === null) {
      return;
    }

    try {
      setLoading(true);

      const payload: RegisterMerchantUserPayload = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        timeZoneId: data.timeZoneId,
        password: data.password,
        token: validationMerchantResult.token,
      };

      const result = await registerMerchantUser(payload);

      if (result.status === 'ok') {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        showNotification('error', result.message ?? 'Unknown error');
      }
    } catch (err: any) {
      showNotification('error', err.data.message ?? 'Unknown error');
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToLogin = () => {
    history.push(LOGIN);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Paper className={classes.paper}>
        <img
          className={classes.logo}
          src={group.data?.logoUrl}
          alt="Register for merchant account"
        />
        <h1>Merchant Account Registration</h1>
        <Box className={classes.stepperRoot}>
          <Stepper activeStep={activeStep}>
            {steps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {activeStep === 0 && (
          <Fade in={activeStep === 0} timeout={{enter: 1000, exit: 0}}>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(handleVerifyMerchantApp)}
            >
              <TextField
                required
                label="Application MID"
                name="applicationMid"
                errors={errors}
                control={control}
                autoFocus
              />
              <TextField
                name="dateOfBirth"
                label="Date of Birth"
                placeholder="__/__/____"
                required
                errors={errors}
                control={control}
                transform={{
                  pattern: '99/99/9999',
                }}
                autoFocus={urlQuery.has('mid')}
              />
              <TextField
                fullWidth
                name="ssnLast4"
                label="Last Four of Social Security Number"
                required
                errors={errors}
                control={control}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : <Fragment>Next</Fragment>}
              </Button>
            </form>
          </Fade>
        )}
        {activeStep === 1 && (
          <Fade in={activeStep === 1} timeout={{enter: 1000, exit: 0}}>
            <form
              className={classes.form}
              noValidate
              onSubmit={accountRegistrationForm.handleSubmit(handleCreateAccount)}
            >
              <TextField
                required
                label="First name"
                name="firstName"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
                autoFocus
              />
              <TextField
                required
                label="Last name"
                name="lastName"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
                autoFocus
              />
              <TextField
                required
                label="Email"
                name="email"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
                autoFocus
              />
              <TextField
                required
                label="Phone Number"
                name="phoneNumber"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
                transform={{
                  pattern: '(999) 999-9999',
                }}
                autoFocus
              />
              <Select
                required
                label="Time Zone"
                name="timeZoneId"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
              >
                {TimeZoneIdsList.sortAndMap(
                  zone => (
                    <MenuItem key={zone} value={zone}>
                      {TimeZoneValues[zone as TimeZone]}
                    </MenuItem>
                  ),
                  zone => TimeZoneValues[zone as TimeZone]
                )}
              </Select>
              <TextField
                required
                label="Password"
                name="password"
                type="password"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
              />
              <TextField
                required
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                errors={accountRegistrationForm.formState.errors}
                control={accountRegistrationForm.control}
                register={accountRegistrationForm.register}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : <Fragment>Next</Fragment>}
              </Button>
            </form>
          </Fade>
        )}
        {activeStep === 2 && (
          <Fade in={activeStep === 2} timeout={{enter: 1000, exit: 0}}>
            <Box>
              <p>You're ready to start enjoying merchant portal!</p>
              <p>
                To access the merchant portal, please use your credentials on the{' '}
                <Link to={LOGIN}>login page</Link>.
              </p>
              <p>
                We’re here to provide support for your business 24/7/365. Please feel free to reach
                out to our team at 866-849-2445.
              </p>
            </Box>
          </Fade>
        )}
      </Paper>

      <Box mt={8}>
        {!!group.data && (
          <Copyright groupName={group.data.name} groupDomain={group.data.portalDomain} />
        )}
      </Box>
      <Dialog
        open={merchantAlreadyAssignedModalOpen}
        onClose={() => setMerchantAlreadyAssignedModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Found existing merchant account'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A merchant account has already been registered for this MID. You can login to portal by
            clicking the button below.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMerchantAlreadyAssignedModalOpen(false)}>Cancel</Button>
          <Button onClick={handleNavigateToLogin} autoFocus>
            Take me to the Portal login
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MerchantRegistration;
