/* eslint-disable react-hooks/exhaustive-deps */
import {
  Agent,
  AgentView,
  Application,
  ApplicationView,
  AsyncState,
  Collections,
  Firebase,
  GroupRole,
  Merchant,
  MerchantView,
  selectAgentView,
  selectMerchantView,
  UniversalSnapshot,
  useGetAuthorizedAgents,
  UserRoles,
} from '@ozark/common';
import {MidAndDba} from '@ozark/common/components';
import {MerchantPortfolioShortInfo} from '@ozark/common/types/MerchantInfo';
import {useCallback, useEffect, useState} from 'react';
import {useAuthUser} from './useAuthUser';

export const useAuthProfile = (authUserStore: ReturnType<typeof useAuthUser>) => {
  const [authProfile, setAuthProfile] = useState<AsyncState<AgentView | MerchantView>>({
    promised: true,
  });

  const {authUser, claims} = authUserStore;

  const getAuthorizedAgents = useGetAuthorizedAgents({
    groupId: claims?.groupId,
    groupRole: claims?.groupRole,
    role: claims?.role,
    uid: authUser?.data?.uid,
  });

  function getCollectionByRole(role: UserRoles) {
    if (role === UserRoles.agent) {
      return Collections.agents;
    }

    return Collections.merchants;
  }

  const getAuthorizedMerchantPortfolio = async (
    id: string
  ): Promise<MerchantPortfolioShortInfo> => {
    return Firebase.firestore
      .collection(Collections.applications)
      .doc(id)
      .get()
      .then(ref => {
        const data = ref.data() as Application;
        return {
          id: ref.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mid: {
            mid: data.mid,
            doingBusinessAs: data.doingBusinessAs,
          } as MidAndDba,
          phoneNumber: data.businessPhone,
          createdAt: data.createdAt,
          customerServiceEmail: data.customerServiceEmail,
          accountStatus: data.accountStatus,
        } as MerchantPortfolioShortInfo;
      });
  };

  useEffect(() => {
    if (authUser.promised || !claims) return;

    const collection = getCollectionByRole(claims.role);

    const unsubscribe = Firebase.firestore
      .collection(collection)
      .doc(authUser.data?.uid)
      .onSnapshot(
        snapshot => {
          if (!snapshot.exists) {
            setAuthProfile({promised: false});
            return;
          }

          setAuthProfile({
            promised: false,
            data:
              claims.role === UserRoles.agent
                ? selectAgentView(snapshot as UniversalSnapshot<Agent>)
                : selectMerchantView(snapshot as UniversalSnapshot<Merchant>),
          });
        },
        err => {
          console.error(err);
          setAuthProfile({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [authUser.promised, authUser.data?.uid, claims]);

  const isAllowedToEditApplication = (application: ApplicationView) => {
    const profile = authProfile.data;
    if (!profile || !profile.role || !profile.workflow) {
      return false;
    }

    return (
      profile.workflow.viewable.includes(application.disposition) ||
      profile.role === UserRoles.admin ||
      application.uid === profile.uid
    );
  };

  const isUserAdmin = useCallback(
    () => authProfile?.data?.role === GroupRole.administrator,
    [authProfile?.data?.role]
  );
  const isUserMember = useCallback(
    () => authProfile?.data?.role === GroupRole.member,
    [authProfile?.data?.role]
  );

  const isUserGroupAdmin = useCallback(
    () => claims?.groupRole === GroupRole.administrator,
    [claims?.groupRole]
  );

  const isUserAgent = useCallback(() => claims?.role === UserRoles.agent, [claims?.role]);
  const isUserMerchant = useCallback(() => claims?.role === UserRoles.merchant, [claims?.role]);

  return {
    authProfile,
    isUserAdmin,
    isUserMember,
    isUserGroupAdmin,
    isUserAgent,
    isUserMerchant,
    getAuthorizedMerchantPortfolio,
    getAuthorizedAgents,
    isAllowedToEditApplication,
  };
};
