import {Box, CircularProgress} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Agent,
  MinMaxBatchInfo,
  SearchCriteria,
  useInfiniteData,
  useNotification,
  ViewBase,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {MinMaxQuery} from '@ozark/common/api/TransactionsApi';
import {
  ExportProps,
  Filter,
  getMerchantPortfolioCardColumnsConfig,
  MerchantPortfolioBar,
  MerchantPortfolioCard,
  MerchantPortfolioHeader,
} from '@ozark/common/components';
import {getMerchantsAuthorizedByClaims} from '@ozark/common/helpers';
import {CancelTokenSource} from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    groupFilter: {
      minWidth: 200,
    },
    divider: {
      margin: theme.spacing(2),
    },
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    icon: {
      height: '100%',
      padding: 0,
      margin: theme.spacing(0, 3),
      '& > *': {
        fill: '#4d6575',
      },
    },
  })
);

const MerchantsPortfolio = () => {
  const showNotification = useNotification();
  const classes = useStyles();
  const history = useHistory();
  const {claims, getAuthorizedAgents, authProfile, apiClient} = useStore();

  const [agents, setAgents] = useState<ViewBase<Agent>[]>([]);
  const [mids, setMids] = useState<string[]>([]);

  const [merchantsData, setMerchantsData] = useState<MinMaxBatchInfo[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(1);

  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filter>({});
  const [midsLoaded, setMidsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const [options, setOptions] = useState<{order: 'desc' | 'asc'; orderBy: string}>({
    order: 'asc',
    orderBy: 'lastBatchDate',
  });

  const [filteredMids, setFilteredMids] = useState<{mids: string[] | null; displayAll: boolean}>({
    mids: null,
    displayAll: true,
  });

  const getBatches = async (page: number) => {
    setLoading(true);
    try {
      const minMaxQuery: MinMaxQuery = {
        search,
        filters: Object.values(filters),
      };
      if (selectedAgentId) {
        minMaxQuery.agentId = selectedAgentId;
      }
      const cancelSource = apiClient?.transactions.getCancelTokenSource();
      setCancelTokenSource(prev => {
        //Check if there are any previous pending requests
        if (prev !== undefined) {
          prev.cancel(CancelOperationMessage);
        }
        return cancelSource;
      });
      const result = await apiClient.transactions.getMinMaxBatches(
        {limit: 8, offset: page, ...options} as SearchCriteria,
        filteredMids.displayAll ? null : filteredMids.mids ?? [],
        minMaxQuery,
        cancelSource?.token
      );

      if (!result) {
        throw new Error('getMinMaxBatches result is null or undefined');
      }

      const merchants = page === 1 ? [...result.data] : [...merchantsData, ...result.data];

      setMerchantsData(merchants);

      setPageNum(page);
      setHasMore(merchants.length < result.totalCount);
      setLoading(false);
    } catch (error: any) {
      if (error?.message === CancelOperationMessage) {
        return;
      }
      showNotification('error', 'Failed to load data');
      console.error('Failed to load min max batches data with an error:', error);
      setLoading(false);
    }
  };

  const onLoadMore = () => {
    if (loading) {
      return;
    }
    getBatches(pageNum + 1);
  };

  const loadMoreRef = useInfiniteData(onLoadMore, hasMore);

  useEffect(() => {
    if (!midsLoaded) {
      return;
    }
    setMerchantsData([]);
    getBatches(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMids, search, options, filters, midsLoaded]);

  const onSortChange = (orderBy: string, order: 'desc' | 'asc') => {
    setOptions({...options, order, orderBy});
  };

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      if (!agents?.length) {
        return;
      }
      setAgents(agents);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMerchantsAuthorizedByClaims({
      uid: authProfile.data?.id,
      claims,
      agentId: undefined,
      subAgentUids: authProfile.data?.subAgentUids,
    }).then(merchants => {
      setMidsLoaded(true);
      if (!merchants?.length) {
        return;
      }
      setMids(merchants.map(m => m.mid));
    });
    // eslint-disable-next-line
  }, [authProfile.data?.id, claims]);

  useEffect(() => {
    if (!mids?.length) {
      return;
    }

    setFilter(undefined, mids);
    // eslint-disable-next-line
  }, [mids]);

  const getFilter = (groupMids?: string[], agentMids?: string[]): string[] | null => {
    if (!groupMids && !agentMids) {
      return null;
    }

    if (groupMids?.length) {
      return groupMids;
    }

    if (agentMids?.length) {
      return agentMids;
    }

    return null;
  };

  const setFilter = (groupMids?: string[], agentMids?: string[], returnAll = false) =>
    setFilteredMids({
      mids: getFilter(groupMids, agentMids),
      displayAll: returnAll,
    });

  const onAgentFilterChange = async (id: string) => {
    if (!agents?.length) {
      return;
    }

    if (id === '0') {
      setSelectedAgentId(null);
      setFilter(mids, undefined, mids?.length === 0);
      return;
    }

    const agent = agents.filter(a => a.id === id)[0];

    if (!agent) {
      return;
    }

    setSelectedAgentId(id);

    let agentMids: string[] | undefined = (
      await getMerchantsAuthorizedByClaims({
        uid: authProfile.data?.id,
        claims,
        agentId: id,
      })
    ).map(merchant => merchant.mid);

    if (!agent.subAgentUids?.length) {
      if (!agentMids?.length) {
        agentMids = undefined;
      }
      setFilter(undefined, agentMids);
      return;
    }

    if (!agentMids) {
      agentMids = [];
    }

    for (const agentId of agent.subAgentUids) {
      agentMids = agentMids.concat(
        (
          await getMerchantsAuthorizedByClaims({
            uid: authProfile.data?.id,
            claims,
            agentId,
          })
        ).map(merchant => merchant.mid)
      );
    }

    if (!agentMids?.length) {
      agentMids = undefined;
    }

    setFilter(undefined, agentMids);
  };

  const handleSearchChange = useCallback((search: string) => setSearch(search), [setSearch]);

  const getAllDataForExport = useCallback(async () => {
    if (!apiClient) return [];

    const result = await apiClient.transactions.getMinMaxBatches(
      {limit: 0, offset: 0, ...options} as SearchCriteria,
      filteredMids.displayAll ? null : filteredMids.mids ?? [],
      {search, filters: Object.values(filters)}
    );
    return result?.data ?? [];
  }, [apiClient, options, search, filters, filteredMids]);

  const exportProps: ExportProps = {
    filename: 'merchants-portfolio-report',
    columnsConfig: getMerchantPortfolioCardColumnsConfig(),
    getRows: getAllDataForExport,
  };

  const route = (m: MinMaxBatchInfo, path?: string) =>
    `${ROUTES.MERCHANTS_PORTFOLIO}/${m.id}${path ? '/' + path : ''}`;

  return (
    <div className={classes.root}>
      <MerchantPortfolioBar
        linkClick={() => history.push(ROUTES.MERCHANTS_PORTFOLIO)}
        agents={agents}
        selectedAgent={selectedAgentId}
        showAgentsFilter={agents?.length > 0}
        onAgentChange={onAgentFilterChange}
        onSortChange={onSortChange}
        onSearchChange={handleSearchChange}
        orderBy={options.orderBy}
        order={options.order}
        filters={filters}
        setFilters={setFilters}
        exportProps={exportProps}
      />

      <MerchantPortfolioHeader />

      {merchantsData.map((m: MinMaxBatchInfo, i: number) => (
        <MerchantPortfolioCard
          key={m.id}
          merchant={m}
          isLastElement={merchantsData.length - 1 === i}
          loadMoreRef={merchantsData.length - 1 === i && hasMore ? loadMoreRef : undefined}
          onClick={() => history.push(route(m), {referrer: 'Merchants'})}
          info={{
            profile: {
              name: 'Profile',
              url: () =>
                history.push(route(m), {
                  referrer: 'Merchants',
                }),
            },
            applications: {
              name: 'Applications',
              url: () =>
                history.push(route(m, 'applications'), {
                  referrer: 'Applications',
                }),
            },
            transactions: {
              name: 'Transactions',
              url: () =>
                history.push(route(m, 'transactions'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            batches: {
              name: 'Batches',
              url: () =>
                history.push(route(m, 'batches'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            authorizations: {
              name: 'Authorizations',
              url: () =>
                history.push(route(m, 'authorizations'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            statements: {
              name: 'Statements',
              url: () =>
                history.push(route(m, 'statements'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            deposits: {
              name: 'Deposits',
              url: () =>
                history.push(route(m, 'deposits'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
            chargebacks: {
              name: 'Disputes',
              url: () =>
                history.push(route(m, 'chargebacks'), {
                  referrer: 'MerchantsPortfolio',
                }),
            },
          }}
        />
      ))}
      {loading && (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </div>
  );
};

export default MerchantsPortfolio;
