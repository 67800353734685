import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Grow,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationDisputeReason,
  Dispositions,
  DocumentsUploadPayload,
  PENDING_REASON_USE_PERSONA,
  PendingAdditionalVerification,
  PendingReasonsRaw,
  ReasonInputType,
  ScorecardServiceName,
  UrlToOpenTemplate,
  useApiClient,
  useNotification,
} from '@ozark/common';
import {getReasonsByDisposition, Loading} from '@ozark/common/components';
import {InsertAttachmentsDialog} from '@ozark/common/components/Attachments/InsertAttachmentsDialog';
import {useCallable} from '@ozark/common/hooks/useCallable';
import {GetVerifiedDocumentsUploadTokenResult} from '@ozark/functions/src/functions/callable/dispatch_GetVerifiedDocumentsUploadToken';
import {UploadAttachmentResponse} from '@ozark/functions/src/functions/express/private/types';
import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import camelcase from 'lodash/camelCase';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import RichTextEditor, {EditorValue} from 'react-rte';
import {useStore} from '../../store/helpers';
import {DocumentUploadFinicityLink} from './DocumentUploadFinicityLink';
import {DocumentUploadPlaidLink} from './DocumentUploadPlaidLink';
import {PersonaIdVerificationLink} from './PersonaIdVerificationLink';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '100vh',
  },
  mainContainer: {
    margin: theme.spacing(theme.spacing(6), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
    width: `calc(100% - 2px)`,
  },
  details: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  optionsSM: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
  hidden: {
    display: 'none',
  },
  container: {
    maxWidth: '100%',
  },
  logo: {
    margin: theme.spacing(theme.spacing(16), 0, theme.spacing(6), 0),
    width: 300,
  },
  uploadedDocuments: {
    margin: theme.spacing(theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
    alignSelf: 'flex-start',
  },
  uploadedDocument: {
    display: 'flex',
    flexDirection: 'row',
  },
  uploadedDocumentsSuccess: {
    marginTop: theme.spacing(-1),
  },
  addDocument: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
  },
  largeIcon: {
    '& svg': {
      fontSize: 50,
    },
  },
  smallIcon: {
    '& svg': {
      fontSize: 20,
    },
  },
  infoButton: {
    marginTop: theme.spacing(-0.75),
  },
  progressBox: {
    marginTop: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(8)})`,
  },
  progress: {
    height: theme.spacing(3),
  },
  optionBase: {
    border: '1px solid',
    height: '100%',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(3),
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  option: {
    width: '30%',
    margin: theme.spacing(0, theme.spacing(1), theme.spacing(1), 0),
  },
  optionSM: {
    width: '100%',
    margin: theme.spacing(0, theme.spacing(1), theme.spacing(1), theme.spacing(0)),
  },
  optionHeader: {
    alignSelf: 'flex-start',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(-1),
    '& svg': {
      marginBottom: theme.spacing(-0.5),
    },
  },
  infoContainerBase: {
    whiteSpace: 'pre-line',
    border: '2px solid',
    borderColor: 'blue',
    boxShadow: 'none',
    padding: theme.spacing(theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  infoContainer: {
    minWidth: theme.spacing(50),
    maxWidth: theme.spacing(50),
  },
  info: {
    marginLeft: 12,
  },
  documentTitle: {
    fontWeight: 500,
    textAlign: 'center',
    //margin: theme.spacing(theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
  },
  plaidText: {
    lineHeight: 1.1,
    margin: theme.spacing(theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
  },
  plaidButton: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  mainText: {
    whiteSpace: 'pre-line',
    lineHeight: 1.1,
    margin: theme.spacing(theme.spacing(2), theme.spacing(2), theme.spacing(2), theme.spacing(2)),
  },
  fileSize: {
    margin: theme.spacing(theme.spacing(4), 0, theme.spacing(1), 0),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  richTextEditorReason: {
    margin: theme.spacing(theme.spacing(2), 0, theme.spacing(2), 0),
    '& button[title="Align Left"]': {
      display: 'none',
    },
    '& button[title="Align Center"]': {
      display: 'none',
    },
    '& button[title="Align Right"]': {
      display: 'none',
    },
    '& button[title="Align Justify"]': {
      display: 'none',
    },
    '& button[title="Link"]': {
      display: 'none',
    },
    '& button[title="Remove Link"]': {
      display: 'none',
    },
    '& button[title="Image"]': {
      display: 'none',
    },
    '& .public-DraftEditor-content': {
      minHeight: '138px',
    },
  },
  commentSection: {
    width: '100%',
  },
  saveButton: {
    margin: theme.spacing(0, 0, theme.spacing(2), 0),
    width: `100%`,
  },
  fileName: {
    overflowWrap: 'anywhere',
  },
}));

type Document = {
  name: string;
  title: string;
  additionalVerification?: PendingAdditionalVerification;
  fileLabel: string;
  mainText: string;
  isFile: boolean;
  uploaded: boolean;
  files: string[];
  info: string;
  verified: boolean;
  linkedBank?: string;
  disputeComment: string | null;
  disputeValue: string | null;
};

type disputeField = {
  key: string;
  value: null | string | string[] | boolean | EditorValue;
};

export const DocumentsUpload = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down(1350));
  const {group} = useStore();
  const showNotification = useNotification();
  const {getVerifiedDocumentsUploadToken} = useCallable();
  const [isCorrectToken, setIsCorrectToken] = useState<boolean | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [decodedToken, setDecodedToken] = useState<DocumentsUploadPayload>();
  const [currentDocument, setCurrentDocument] = useState<string>();
  const {apiClient} = useApiClient({promised: false});
  const [saving, setSaving] = useState(false);
  const disputeReasons = getReasonsByDisposition(Dispositions.uwPending);
  const [progress, setProgress] = React.useState(0);
  const [textEditorText, setTextEditorText] = useState(
    disputeReasons.map(
      ({name}: {name: string}): disputeField => ({
        key: name,
        value: RichTextEditor.createEmptyValue(),
      })
    )
  );
  const [fields, setFields] = useState<disputeField[]>(
    disputeReasons.map(({name}: {name: string}): disputeField => ({key: name, value: null}))
  );
  const [expanded, setExpanded] = React.useState<string | false>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, doc: string) => {
    setAnchorEl(event.currentTarget);
    setCurrentDocument(doc);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const documentsUploadToken = window.location.hash.replace('#token=', '');

  const getFieldValue = (name: string) => fields.find(field => field.key === name)?.value;
  const getTextEditorValue = (name: string) =>
    textEditorText.find(field => field.key === name)?.value;
  const updateField = (name: string, value: string) => {
    const _fields = fields.slice();
    const idx = fields.findIndex(field => field.key === name);
    _fields.splice(idx, 1, {key: name, value});
    setFields(_fields);
  };
  const updateRichEditor = (name: string, event: EditorValue) => {
    const _textFields = textEditorText.slice();
    const textIdx = textEditorText.findIndex(field => field.key === name);
    _textFields.splice(textIdx, 1, {key: name, value: event});
    setTextEditorText(_textFields);
  };
  const displaySaveButton = (name: string) => {
    const value = getFieldValue(name);
    return (
      !!value &&
      typeof value === 'string' &&
      (value ?? '').replace(/<p>(?:&nbsp;|<br>)*<\/p>/gm, '').trim().length > 0
    );
  };

  useEffect(() => {
    if (documentsUploadToken.length === 0) {
      return;
    }

    const getDocuments = async () => {
      const result = (await getVerifiedDocumentsUploadToken({
        token: documentsUploadToken,
      })) as GetVerifiedDocumentsUploadTokenResult;
      if (result.status !== 'ok' || !result.token) {
        console.error(`Failed to check documents upload token`, result);
        showNotification('error', 'Failed to check documents upload token');
        setIsCorrectToken(false);
        return;
      }

      const pendingReasons = PendingReasonsRaw.filter(x =>
        result.token?.documents.includes(camelcase(x.name))
      );

      const docs = pendingReasons
        .map(x => {
          const disputeUwValue =
            result.disputeUwPendingReasons?.find(
              (reason: ApplicationDisputeReason) =>
                reason.key === camelcase(x.name) && typeof reason.value === 'string'
            )?.value ?? null;

          let termsUrl = group.data?.termsUrl ?? 'https://www.tangrampayments.com/tandc';
          if (!termsUrl.startsWith('https://')) {
            termsUrl = `https://${termsUrl}`;
          }
          return {
            name: camelcase(x.name),
            additionalVerification: x.additionalVerification,
            title: x.title,
            fileLabel: x.document,
            mainText: x.text.replace(UrlToOpenTemplate, termsUrl),
            isFile: !!x.document,
            uploaded: !!x.document && result.attachments?.includes(x.document),
            verified:
              (!!x.document && result.attachments?.includes(x.document)) ||
              result.notes?.includes(x.title),
            files: [],
            info: x.info,
            disputeComment:
              disputeUwValue && x.type !== ReasonInputType.Select
                ? `${x.description}<br />${disputeUwValue}`
                : null,
            disputeValue: disputeUwValue,
          } as Document;
        })
        .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

      setProgress((docs?.filter(d => d.verified).length / docs.length) * 100);

      setExpanded(docs[0]?.name); // expand first reason
      setDocuments(docs);
      setDecodedToken(result.token);
      setIsCorrectToken(true);
    };

    getDocuments().catch(err => {
      console.error(`Failed to check documents upload token`, err);
      setIsCorrectToken(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsUploadToken, group.data?.termsUrl]);

  const handleFileUpload = async (file: File) => {
    if (!currentDocument) {
      return;
    }

    if (file.size === 0) {
      throw new Error('This file was not uploaded correctly. Reupload it to try again.');
    }

    const formData = new FormData();
    formData.append('file', file);

    const response: UploadAttachmentResponse = await apiClient.attachments.upload(formData, {
      token: documentsUploadToken,
      document: currentDocument,
    });
    const {emailUrl, filePath, url} = response;

    if (filePath) {
      await apiClient.attachments.addNote({
        token: documentsUploadToken,
        note: getAttachmentNote(currentDocument, url),
        emailNote: getAttachmentNote(currentDocument, emailUrl),
        tag: currentDocument,
      });
    }
  };

  const handleSelectedFiles = async (attachments: TmpAttachment[]) => {
    if (!currentDocument) {
      return;
    }

    const docs = [...documents];
    for (const attachment of attachments) {
      docs.forEach((doc, i) => {
        if (doc.fileLabel !== currentDocument) {
          return;
        }
        docs[i] = {
          ...doc,
          uploaded: true,
          verified: true,
          files: [...doc.files, attachment.oldFilename],
        };
      });
    }

    setDocuments(docs);
    setProgress((docs?.filter(d => d.verified).length / docs.length) * 100);
    setCreateDialogOpen(false);
  };

  const handleAttachClick = (_: React.MouseEvent, doc: string) => {
    setCurrentDocument(doc);
    setCreateDialogOpen(true);
  };
  const onPersonaVerified = useCallback(
    (license: string) => {
      const updatedDocuments = documents.map(doc => ({
        ...doc,
        verified:
          doc.additionalVerification === PendingAdditionalVerification.persona
            ? true
            : doc.verified,
      }));
      setDocuments([...updatedDocuments]);
      setProgress(
        (updatedDocuments?.filter(d => d.verified).length / updatedDocuments.length) * 100
      );
    },
    [documents]
  );

  const onDepositAccountLinked = useCallback(
    (bankName: string) => {
      const updatedDocuments = documents.map(doc => ({
        ...doc,
        verified: doc.additionalVerification !== undefined ? true : doc.verified,
        linkedBank: bankName,
      }));
      setDocuments([...updatedDocuments]);
      setProgress(
        (updatedDocuments?.filter(d => d.verified).length / updatedDocuments.length) * 100
      );
    },
    [documents]
  );

  const saveComment = async (doc: Document) => {
    setSaving(true);
    const note = getFieldValue(doc.name);
    if (!note) {
      return;
    }

    // mark as verified after adding the note
    const updatedDocuments = documents.map(d => ({
      ...d,
      verified: doc.name === d.name ? true : d.verified,
    }));
    setDocuments([...updatedDocuments]);
    setProgress((updatedDocuments?.filter(d => d.verified).length / updatedDocuments.length) * 100);

    const response = await apiClient.attachments.addNote({
      token: documentsUploadToken,
      note: `${doc.title}: ${note as string}`,
      tag: doc.title,
    });

    if (response) {
      showNotification('success', `New note successfully added`);
      updateRichEditor(doc.name, RichTextEditor.createEmptyValue());
      updateField(doc.name, '');
    } else {
      showNotification('error', 'Failed to add note');
    }
    setSaving(false);
  };

  const displayInstitutionServiceProviderLink = (doc: Document) => {
    const isFinicity = group.data?.institutionServiceProvider === ScorecardServiceName.finicity;
    const optionText = isFinicity
      ? 'Click here to quickly validate your deposit account by logging into Finicity, a MasterCard product. This preferred option speeds up your daily funding.'
      : 'Click here to quickly validate your deposit account with Plaid. This preferred option speeds up your daily funding.';
    const optionButton = isFinicity ? (
      <DocumentUploadFinicityLink
        applicationId={decodedToken!.applicationId}
        group={group}
        token={documentsUploadToken}
        linkedBankAccount={doc.linkedBank}
        onSuccess={onDepositAccountLinked}
      />
    ) : (
      <DocumentUploadPlaidLink
        group={group}
        token={documentsUploadToken}
        onSuccess={onDepositAccountLinked}
      />
    );
    return (
      <Fragment>
        <div
          className={`${classes.optionBase} ${isSmallDevice ? classes.optionSM : classes.option}`}
        >
          <h4 className={classes.optionHeader}>
            Option 1 (Best Option <ThumbUpAltIcon color="primary" />)
          </h4>
          <Typography className={classes.plaidText}>{optionText}</Typography>
          <div className={classes.plaidButton}>{optionButton}</div>
        </div>
      </Fragment>
    );
  };

  const displayPersonaLink = (doc: Document) =>
    doc.disputeValue === PENDING_REASON_USE_PERSONA && (
      <Fragment>
        <div
          className={`${classes.optionBase} ${isSmallDevice ? classes.optionSM : classes.option}`}
          style={{minWidth: 400}}
        >
          <Typography
            dangerouslySetInnerHTML={{__html: doc.mainText}}
            className={classes.mainText}
          ></Typography>
          <div className={classes.plaidButton}>
            <PersonaIdVerificationLink
              applicationId={decodedToken!.applicationId}
              group={group}
              token={documentsUploadToken}
              onSuccess={onPersonaVerified}
            />
          </div>
        </div>
      </Fragment>
    );

  const displayOption1 = (doc: Document) => {
    switch (doc.additionalVerification) {
      case PendingAdditionalVerification.deposit: {
        return displayInstitutionServiceProviderLink(doc);
      }
      case PendingAdditionalVerification.persona: {
        return displayPersonaLink(doc);
      }
      default:
        return null;
    }
  };

  const displayOption2 = (doc: Document) =>
    doc.disputeValue !== PENDING_REASON_USE_PERSONA && (
      <div className={`${classes.optionBase} ${isSmallDevice ? classes.optionSM : classes.option}`}>
        {doc.additionalVerification === PendingAdditionalVerification.deposit && (
          <h4 className={classes.optionHeader}>Option 2</h4>
        )}
        <Typography
          dangerouslySetInnerHTML={{__html: doc.mainText}}
          className={classes.mainText}
        ></Typography>
        {doc.isFile && (
          <>
            {doc.uploaded && (
              <Box className={`${classes.largeIcon}`}>
                <DoneIcon color="success" />
              </Box>
            )}
            <Typography
              className={classes.documentTitle}
              style={{
                color: !doc.uploaded ? 'black' : 'green',
              }}
            >
              {!doc.uploaded ? `` : 'Document Uploaded'}
            </Typography>
            <Button
              key={`${doc.name}-upload-another-doc`}
              className={classes.addDocument}
              variant="contained"
              color="primary"
              onClick={e => {
                handleAttachClick(e, doc.fileLabel);
              }}
              startIcon={<AddCircleIcon />}
            >
              {doc.uploaded ? 'Upload Another Document' : 'Upload Document'}
            </Button>
          </>
        )}
      </div>
    );

  const displayCommentEditor = (doc: Document) => (
    <div className={classes.commentSection}>
      <h4 className={classes.optionHeader}>Leave a comment:</h4>
      <RichTextEditor
        key={`${doc.name}-editor`}
        editorStyle={{
          minHeight: '138px',
          fontFamily: 'Rubik, sans-serif',
        }}
        className={classes.richTextEditorReason}
        onChange={(event: EditorValue) => {
          updateRichEditor(doc.name, event);
          const value = event.toString('html');
          updateField(doc.name, value);
        }}
        value={getTextEditorValue(doc.name) as EditorValue}
      />
      {displaySaveButton(doc.name) && (
        <Button
          key={`${doc.name}-button`}
          variant="outlined"
          color="primary"
          className={classes.saveButton}
          disabled={saving}
          onClick={() => saveComment(doc)}
        >
          {saving ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
        </Button>
      )}
    </div>
  );

  const displayFilesList = (doc: Document) =>
    doc.files?.length > 0 && (
      <div className={classes.uploadedDocuments} key={`${doc.name}-documents`}>
        <h4>Uploaded documents (the current session):</h4>
        {doc.files.map(file => (
          <div className={classes.uploadedDocument}>
            <IconButton className={classes.smallIcon}>
              <DoneIcon className={classes.uploadedDocumentsSuccess} color="success" />
            </IconButton>
            <Typography className={classes.fileName}>{file}</Typography>
          </div>
        ))}
      </div>
    );

  const displayDocInfo = (doc: Document) => (
    <Paper
      className={`${classes.infoContainerBase} ${!isSmallDevice ? classes.infoContainer : ''}`}
    >
      <InfoIcon color="primary" />
      <Typography className={classes.info}>{doc.info}</Typography>
    </Paper>
  );

  const successInfo = () => (
    <>
      {progress > 99 && (
        <Grow in>
          <Box alignSelf="stretch" mx={4} mt={2}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>You have completed required documents upload. You can
              close this window to end the session and you will receive an email shortly with the
              details of your approval to begin processing.
            </Alert>
          </Box>
        </Grow>
      )}
    </>
  );

  if (isCorrectToken === null) return <Loading />;

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Grid className={classes.content} item xs={12}>
          <img className={classes.logo} src={group.data?.logoUrl} alt="Documents upload" />
          {!isCorrectToken && (
            <Typography variant="body2" color="textSecondary" align="center">
              The link has expired or invalid
            </Typography>
          )}
          {isCorrectToken && (
            <>
              <Typography variant="h5">Please upload the following files.</Typography>
              <Typography variant="h5">
                <b>Please note: We are unable to accept the requested documents via email</b>
              </Typography>
              {successInfo()}
              <Box className={classes.progressBox}>
                <h4>Progress: {progress.toFixed(2)}%</h4>
                <LinearProgress
                  className={classes.progress}
                  variant="determinate"
                  value={progress}
                />
              </Box>

              <div className={classes.mainContainer}>
                {documents.map(doc => (
                  <Accordion
                    key={doc.name}
                    expanded={expanded === doc.name}
                    onChange={handleChange(doc.name)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${doc.name}-content`}
                      id={`${doc.name}-header`}
                    >
                      <IconButton
                        className={`${classes.smallIcon} ${classes.infoButton}`}
                        onMouseEnter={e => handlePopoverOpen(e, doc.info)}
                        onMouseLeave={handlePopoverClose}
                      >
                        <InfoIcon color="primary" />
                      </IconButton>
                      <Typography
                        className={classes.documentTitle}
                        style={{
                          color: !doc.verified ? 'black' : 'green',
                        }}
                      >
                        {doc.title}
                      </Typography>
                      {doc.verified && <DoneIcon color="success" />}
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      {!!doc.disputeComment && (
                        <Box
                          sx={{width: '100%', paddingBottom: 2}}
                          dangerouslySetInnerHTML={{__html: doc.disputeComment ?? ''}}
                        />
                      )}
                      <div className={`${isSmallDevice ? classes.optionsSM : classes.options}`}>
                        {displayOption1(doc)}
                        {displayOption2(doc)}
                        {displayDocInfo(doc)}
                      </div>
                      {displayFilesList(doc)}
                      {displayCommentEditor(doc)}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <Popover
                id="popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{p: 1}}>{currentDocument}</Typography>
              </Popover>
              {successInfo()}
              <Typography className={classes.fileSize}>
                Please note: we cannot accept file sizes that total more than 10 MB (10,000 KB)
              </Typography>
            </>
          )}
        </Grid>
        <footer className={classes.footer}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={`${window.location.protocol}//${group.data?.portalDomain}`}>
              {group.data?.name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </footer>
      </Container>
      {createDialogOpen && (
        <InsertAttachmentsDialog
          initialFolderName={null}
          folderNamesOptions={[]}
          handleFileUpload={handleFileUpload}
          onSubmit={handleSelectedFiles}
          onClose={() => setCreateDialogOpen(false)}
          hideFolder={true}
        />
      )}
    </div>
  );
};

const getAttachmentNote = (doc: string, url: string) => {
  return `<p>
    Attachment uploaded: <a href="${url}" target="_blank" rel="noreferrer">${doc}</a>
  </p>`;
};
